import { Group, Space, Stack } from "@mantine/core";
import { Title } from "../ui/Title";
import { useActivePlaylist } from "@/store/selectors/playlistSelectors";
import { PlaylistScale } from "./PlaylistScale";
import { Text } from "../ui/Text";
import { ListOfRecommendedPlaylists } from "./RecommendPlaylists";

export const PlaylistConclusion = () => {
  const activePlaylist = useActivePlaylist();

  return (
    <Stack gap="xs" align="center">
      {activePlaylist?.id && (
        <>
          <Title
            order={1}
            size="h1"
            c="text.9"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            You completed the playlist 🎉
          </Title>

          {/* <Link to={`/playlists/${activePlaylist?.slug}?step=${firstStepKey}`}>
            Start this playlist again
          </Link>
          <Space h="xl" /> */}
          <Space h="xl" />

          <Text
            size="lg"
            c="text.7"
            fw={500}
            maw={400}
            ta="center"
            w="100%"
            lh={1.2}
          >
            What did you think of...
          </Text>

          <Text
            size="xl"
            c="text.9"
            fw={700}
            maw={500}
            ta="center"
            w="100%"
            lh={1.2}
          >
            {activePlaylist?.title}?
          </Text>

          <Space h="xs" />

          <Group justify="center">
            <PlaylistScale
              id="playlist-rating"
              playlistId={activePlaylist?.id}
              lowerText="Not helpful"
              upperText="Super helpful"
              shouldShowFaceIcons
            />
          </Group>
          <Space h="xl" />
          <Space h="xl" />

          <ListOfRecommendedPlaylists />
        </>
      )}

      <Space h="xl" />

      {/* <Title order={3} size="h3">
        What do you want to do next?
      </Title>
      <PlaylistNextSteps /> */}
    </Stack>
  );
};
