import { useStore } from "@/store";
import {
  Avatar,
  Box,
  Group,
  Progress,
  RingProgress,
  Space,
  Stack,
} from "@mantine/core";
import { Text } from "@/components/ui/Text";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import emilyAvatar from "@/assets/emily-avatar.jpeg";
import {
  useActivePlaylist,
  useActivePlaylistAdvizer,
  useActivePlaylistProgress,
  useFirstStepKeyInPlaylist,
  useIsFirstStepInPlaylist,
  useIsOnboardingPlaylist,
  useIsVideoStep,
} from "@/store/selectors/playlistSelectors";
import { IconPlaylist } from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "../ui/Link";
import { useIsAnyVideoPlaying } from "@/store/selectors";

export const PlaylistTopBar = (props: {
  hideAvatar?: boolean;
  maxWidth?: number;
  handleClickNextButton?: () => void;
  bg?: string;
}) => {
  const { hideAvatar = false, maxWidth } = props;
  const { theme } = useStyles();
  let progress = useActivePlaylistProgress();
  const avatarSize = progress ? 100 : 100;
  const ringThickness = 8;
  const ringSize = avatarSize + ringThickness * 4;
  const playlist = useActivePlaylist();
  const showMainNavbarRaw = useStore((state) => state.showMainNavbar);
  const activeAdvizer = useActivePlaylistAdvizer();
  const isFirstStep = useIsFirstStepInPlaylist();
  const isOnboarding = useIsOnboardingPlaylist();
  const isAnyVideoPlaying = useIsAnyVideoPlaying();
  const showMainNavbar = showMainNavbarRaw;
  const showAvatar = showMainNavbar && !hideAvatar;
  const isPlaylistLoading = useStore(
    (state) => state.playlists.isPlaylistLoading,
  );
  const showPlaylistLoading = useStore(
    (state) => state.playlists.showPlaylistLoading,
  );
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isLoadingState = isPlaylistLoading || !playlist || showPlaylistLoading;
  const [isUpdatingProgress, setIsUpdatingProgress] = useState(false);
  const isVideoStep = useIsVideoStep();
  const firstStepKey = useFirstStepKeyInPlaylist();

  if (isOnboarding) {
    progress = 0;
  }

  const renderAvatar = useCallback(() => {
    return (
      <Group
        pos="absolute"
        bottom={-ringSize / 2}
        left={0}
        w="100%"
        justify="center"
        align="center"
        bg={"transparent"}
        display={showAvatar ? undefined : "none"}
      >
        <Box pos="relative">
          <Avatar
            src={activeAdvizer ? activeAdvizer.profilePictureURL : emilyAvatar}
            size={avatarSize}
            alt="Emily, CEO of Advize"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          />
          <RingProgress
            size={ringSize}
            thickness={ringThickness}
            transitionDuration={1000}
            style={
              isLoadingState
                ? {
                    transform: "rotate(0deg)",
                    animation: "spin 1s linear infinite",
                  }
                : undefined
            }
            sections={
              isLoadingState
                ? [
                    {
                      value: 25, // Quarter of the circle
                      color: theme.colors.secondary[7],
                    },
                  ]
                : [
                    {
                      value: progress,
                      color: theme.colors.primary[7],
                    },
                    {
                      value: 100 - progress,
                      color: theme.colors.background[2],
                    },
                  ]
            }
            label={<></>}
          />
        </Box>
      </Group>
    );
  }, [
    activeAdvizer,
    avatarSize,
    isLoadingState,
    progress,
    ringSize,
    ringThickness,
    showAvatar,
    theme.colors.background,
    theme.colors.primary,
    theme.colors.secondary,
  ]);

  useEffect(() => {
    setIsUpdatingProgress(true);
    const timeout = setTimeout(() => {
      setIsUpdatingProgress(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [progress]);

  const PlaylistTitle = useMemo(() => {
    if (!playlist?.title || isFirstStep) return null;

    return (
      <Group
        w="100%"
        justify="flex-start"
        align="flex-start"
        px={6}
        py={4}
        maw={maxWidth}
      >
        <Link
          to={`/playlists/${playlist.slug}?step=${firstStepKey}`}
          style={{ flex: 1 }}
        >
          <Group gap={4} align="flex-start">
            <IconPlaylist
              size={isLaptopOrBigger ? 22 : 18}
              color={theme.colors.primary[9]}
            />{" "}
            <Text
              titleStyle={isLaptopOrBigger ? "h4" : "h5"}
              c="primary.9"
              ta="left"
              flex={1}
              lineClamp={showMainNavbar ? undefined : 1}
              lh={1.2}
            >
              {playlist.title}
            </Text>
          </Group>
        </Link>
        <Group>
          <Text titleStyle="h5" c="text.7">
            {Math.round(progress)}% complete
          </Text>
        </Group>
      </Group>
    );
  }, [
    playlist?.title,
    playlist?.slug,
    isFirstStep,
    maxWidth,
    firstStepKey,
    isLaptopOrBigger,
    theme.colors.primary,
    showMainNavbar,
    progress,
  ]);

  // if (!playlist?.title && !showMainNavbar) return null;

  return (
    <Stack
      px="xs"
      pt="xs"
      gap={0}
      pos="sticky"
      bg="background.1"
      w="100%"
      top={0}
      style={{
        zIndex: 10,
        borderBottom: isVideoStep
          ? `1px solid ${theme.colors.background[2]}`
          : `4px solid ${theme.colors.background[2]}`,
        // boxShadow: !showMainNavbar
        //   ? "0 0 10px 0 rgba(0, 0, 0, 0.1)"
        //   : undefined,
      }}
      align="center"
      justify="space-between"
      pb={showAvatar ? avatarSize / 2 + 4 : "xs"}
    >
      {PlaylistTitle}

      {showMainNavbar ? <Space h="xl" /> : null}

      {!showMainNavbar && playlist?.title && (
        <Group
          w="100%"
          justify="center"
          align="center"
          px={4}
          pt={8}
          maw={maxWidth}
        >
          <Progress
            value={progress}
            color="primary.7"
            w="100%"
            size="md"
            animated={isUpdatingProgress || isAnyVideoPlaying}
          />
        </Group>
      )}

      {renderAvatar()}
    </Stack>
  );
};
