import { config } from "@/config";
import { ANALYTICS_EVENTS } from "@/configs/analyticsEvents";
import { removeUndefinedFromObject } from "@/lib/helpers";
import { IAdvizerVideo } from "@/models/AdvizerVideo";
import { User } from "@/models/User";
import { useStore } from "@/store";
import { Firestore } from "@/services/Firestore";
import mixpanel from "mixpanel-browser";
import { StoreService } from "./StoreService";

export type IAnalyticsMetrics = {
  timestamp?: number;
  videoWatchedSeconds?: number;
  videoDurationSeconds?: number;
  videoWatchedPercent?: number;
  videoSeekFromSeconds?: number;
  videoSeekToSeconds?: number;
  videoHasSeeked?: boolean;
  userSecondsWatched?: number;
};

export type IPIIEventData = {
  event: string;
  data: IAnalyticsMetrics;
};

const analyticsMetricsKeys: (keyof IAnalyticsMetrics)[] = [
  "timestamp",
  "videoWatchedSeconds",
  "videoDurationSeconds",
  "videoWatchedPercent",
  "videoSeekFromSeconds",
  "videoSeekToSeconds",
  "videoHasSeeked",
  "userSecondsWatched",
];

export type IAnalyticsEventData = IAnalyticsMetrics & {
  event?: string;
  action?: string;
  label?: string;
  // custom dimensions
  userAdvizeId?: string;
  userEmail?: string;
  userEmailDomain?: string;
  userPartnerId?: string;
  userPartnerSlug?: string;
  userPartner?: string;

  email?: string;
  inputValue?: string;
  value?: number;
  error?: string;
  filterType?: string;
  filterValue?: string;
  resourceId?: string;
  context?: string;
  page?: string;
  url?: string;

  partner?: string;
  partnerId?: string;
  partnerSlug?: string;
  advizerId?: string;
  advizerName?: string;

  // video dimensions
  videoId?: string;
  questionNumber?: number;
  videoTitle?: string;

  timestampMetric?: number;
  videoWatchedSecondsMetric?: number;
  videoDurationSecondsMetric?: number;
  videoWatchedPercentMetric?: number;
  videoSeekFromSecondsMetric?: number;
  videoSeekToSecondsMetric?: number;
  videoHasSeekedMetric?: boolean;
  userSecondsWatchedMetric?: number;

  // playlists
  isPlaylistVideo?: boolean;
  playlistId?: string;
  playlistTitle?: string;
  playlistStepKey?: string;
  playlistStepTitle?: string;
  playlistStepType?: string;
  isRecommendedPlaylist?: boolean;
  // // METRICS
  // timestamp?: number;
  // videoWatchedSeconds?: number;
  // videoDurationSeconds?: number;
  // videoWatchedPercent?: number;
  // videoSeekFromSeconds?: number;
  // videoSeekToSeconds?: number;
  // videoHasSeeked?: boolean;
  // userSecondsWatched?: number;
};

declare function gtag(
  command: string,
  event: string,
  data?: IAnalyticsEventData,
): void;

const debug = true;
class AnalyticsService {
  trackEvent(event: keyof typeof ANALYTICS_EVENTS, data?: IAnalyticsEventData) {
    try {
      const { activeUser: activeUserRaw } = useStore.getState().user;
      const activeUser = activeUserRaw ? new User(activeUserRaw) : null;
      const eventConfig = ANALYTICS_EVENTS[event];
      const email = data?.email || activeUser?.email;
      const activeUserPartner = useStore.getState().partner.activePartner;

      if (!eventConfig) {
        console.error(`Event config not found for ${event}`);
        return;
      }

      const doNotTrack =
        activeUser?.email &&
        (activeUser?.rejectedCookies || !activeUser?.acceptedCookies) &&
        !eventConfig?.anonymous;

      const emailsToIgnore = [
        "yosttest",
        "ryost217",
        "rjyost",
        "ryanjyost",
        "emilymcsherry",
        "emilytest",
      ];

      let eventParams: IAnalyticsEventData = {
        event,
        url: window.location.pathname,
        timestamp: Date.now(),
      };

      if (activeUser && !eventConfig?.anonymous) {
        eventParams.userAdvizeId = activeUser.uid;
        eventParams.userEmail = activeUser.email;
      }

      if (email) {
        eventParams.userEmailDomain = email.split("@")[1];
      }

      if (data?.partnerId && !data.partner) {
        const partners = useStore.getState().advizeApiData.partners;
        const partner = partners?.find((p) => p.id === data.partnerId);
        eventParams.partner = partner?.title;
      }

      if (data?.partnerId && !data.partnerSlug) {
        const partners = useStore.getState().advizeApiData.partners;
        const partner = partners?.find((p) => p.id === data.partnerId);
        eventParams.partnerSlug = partner?.slug;
      }

      if (activeUserPartner) {
        eventParams.userPartner = activeUserPartner.title;
        eventParams.userPartnerId = activeUserPartner.id;
        eventParams.userPartnerSlug = activeUserPartner.slug;
      }

      if (data) {
        eventParams = { ...eventParams, ...data };
      }

      // loop over the metrics types, and if any exist in eventParams, then add "Metric" to end of the prop name and make a new prop with the value explicitly as a number
      analyticsMetricsKeys.forEach((metric) => {
        if (typeof eventParams[metric] !== "undefined") {
          (eventParams as any)[`${String(metric)}Metric`] = Number(
            eventParams[metric],
          );
        }
      });

      if (
        doNotTrack ||
        (!config.isLocalClient &&
          emailsToIgnore.some((email) => activeUser?.email?.includes(email)))
      ) {
        console.log("Untracked event: ", {
          eventParams,
          doNotTrack,
          config,
          // emailsToIgnore,
        });
        return;
      }

      try {
        eventParams = removeUndefinedFromObject(eventParams);
      } catch (e) {
        console.error("Error removing undefined from event params", e);
        // eventParams = { ...eventParams };
      }

      if (debug || config.isProductionClient) {
        console.log("EVENT: ", event, eventParams);
      }

      if (config.isProductionClient) {
        console.log("Saving analytics event");
        try {
          if (!eventConfig.excludeFromFirestore) {
            Firestore.saveEvent({ event, data: eventParams });
          }
        } catch (e) {
          console.error("Error saving event to Firestore", e);
        }

        try {
          gtag("event", event, eventParams);
        } catch (e) {
          console.error("Error sending event to Google Analytics", e);
        }

        try {
          mixpanel.track(eventConfig?.title || event, eventParams);
        } catch (e) {
          console.error("Error sending event to Mixpanel", e);
        }
      }
    } catch (e) {
      console.error(`Error tracking event: ${event}`, e);
    }
  }

  click({
    action,
    event,
    label,
    context,
  }: {
    action: string;
    event?: any;
    label?: string;
    context?: string;
  }) {
    // event is native event here, not string
    this.trackEvent("click", {
      action,
      label:
        label || event?.target?.innerText || event?.target?.textContent || null,
      context,
    });
  }

  invalidEmail(data: IAnalyticsEventData) {
    this.trackEvent("invalid_email", { ...data });
  }

  loginAttempt(data: IAnalyticsEventData) {
    this.trackEvent("login_attempt", { ...data });
  }

  login(data: IAnalyticsEventData) {
    this.trackEvent("login", { ...data });
  }

  logout() {
    this.trackEvent("logout");
  }

  register(data: IAnalyticsEventData) {
    this.trackEvent("register", { ...data });
  }

  error(data: IAnalyticsEventData) {
    this.trackEvent("error", data);
  }

  filterAdvizers(data: IAnalyticsEventData) {
    this.trackEvent("filter_advizers", { context: "Advizer Search", ...data });
  }

  noAdvizersFound(data: IAnalyticsEventData) {
    this.trackEvent("no_advizers_found", { ...data });
  }
  startVideo({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    console.log({ video });
    this.trackEvent("video_start", { ...this.getVideoParams(video), ...rest });
  }
  videoProgress({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    this.trackEvent("video_progress", {
      ...this.getVideoParams(video),
      ...rest,
    });
  }
  pauseVideo({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    this.trackEvent("video_pause", { ...this.getVideoParams(video), ...rest });
  }

  seekVideo({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    this.trackEvent("video_seek", { ...this.getVideoParams(video), ...rest });
  }

  stopVideo({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    this.trackEvent("video_stop", { ...this.getVideoParams(video), ...rest });
  }

  acceptCookies() {
    this.trackEvent("accept_cookies");
  }

  rejectCookies() {
    this.trackEvent("reject_cookies");
  }

  videoError(data: IAnalyticsEventData) {
    this.trackEvent("video_error", data);
  }

  videoProgressEvery5Seconds({
    video,
    ...rest
  }: { video: IAdvizerVideo } & IAnalyticsEventData) {
    this.trackEvent("video_progress_every_5_seconds", {
      ...this.getVideoParams(video),
      ...rest,
    });
  }

  pageNotFound() {
    this.trackEvent("page_404");
  }

  updateUserProfile(data: IAnalyticsEventData) {
    this.trackEvent("update_user_profile", {
      ...data,
      ...this.getActivePlaylistParams(),
    });
  }

  filterPlaylists(data: IAnalyticsEventData) {
    this.trackEvent("filter_playlists", data);
  }

  playlistStart(data: IAnalyticsEventData & { playlist: any }) {
    const { playlist, ...rest } = data;
    this.trackEvent("playlist_start", {
      ...this.getActivePlaylistParams({
        playlist,
      }),
      ...rest,
    });
  }

  playlistComplete(data?: IAnalyticsEventData) {
    this.trackEvent("playlist_complete", {
      ...this.getActivePlaylistParams(),
      ...data,
    });
  }

  getRecommendedPlaylists(data: IAnalyticsEventData) {
    this.trackEvent("get_recommended_playlists", {
      ...this.getActivePlaylistParams(),
      ...data,
    });
  }

  playlistScaleSelect(data: IAnalyticsEventData) {
    this.trackEvent("playlist_scale_select", {
      ...this.getActivePlaylistParams(),
      ...data,
    });
  }

  playlistError(data: IAnalyticsEventData) {
    this.trackEvent("playlist_error", {
      ...this.getActivePlaylistParams(),
      ...data,
    });
  }

  // playlistEvent(data: IAnalyticsEventData) {
  //   this.trackEvent("playlist_event", data);
  // }

  private getActivePlaylistParams(params?: {
    playlist?: any;
    stepKey?: string;
  }) {
    const playlist =
      params?.playlist || StoreService.getPlaylistState().activePlaylist;

    if (!playlist) return {};

    const stepKey =
      params?.stepKey || StoreService.getActivePlaylistStepKey() || undefined;

    const playlistRecommendations =
      StoreService.getUserState().playlistData?.recommendedPlaylists || {};

    const playlistStep = stepKey ? playlist.steps[stepKey] : undefined;

    console.log({ playlistStep, stepKey });

    return {
      playlistId: playlist.id,
      playlistTitle: playlist.title,
      playlistStepKey: stepKey,
      playlistStepTitle: playlistStep?.title,
      playlistStepType: playlistStep?.stepType,
      isRecommendedPlaylist: !!playlistRecommendations[playlist.id],
    };
  }

  private getVideoParams(video: IAdvizerVideo) {
    return {
      ...this.getActivePlaylistParams(),
      videoId: video.id,
      vimeoId: video.videoVimeoId,
      videoUrl: video.url,
      partner: (video.advizer?.partners || [])
        .map((partner) => partner.title)
        .join("|"),
      partnerId: (video.advizer?.partners || [])
        .map((partner) => partner.id)
        .join("|"),
      partnerSlug: (video.advizer?.partners || [])
        .map((partner) => partner.slug)
        .join("|"),
      advizerId: video.advizer?.id,
      advizerName: video.advizer?.name,
      questionNumber: video.questionNumber,
      videoTitle: video.title,
    };
  }
}

export const Analytics = new AnalyticsService();
