import { Image } from "@/components/ui/Image";
import { usePartner, usePartnerBySlug } from "@/store/selectors";
import { useStyles } from "@/styles/useStyles";
import { Flex, Skeleton } from "@mantine/core";
import { useCallback } from "react";

export const PartnerLogo = (props: {
  width?: number;
  height?: number;
  radius?: number;
  src?: string | null;
  disableClick?: boolean;
  partnerId?: string;
  backgroundColor?: string;
  noLink?: boolean;
  partnerSlug?: string;
}) => {
  const { theme } = useStyles();
  const {
    width,
    height,
    radius,
    src,
    partnerId,
    backgroundColor,
    partnerSlug,
  } = props;
  const partnerFromId = usePartner(partnerId);
  const partnerFromSlug = usePartnerBySlug(partnerSlug);
  const partner = partnerFromId || partnerFromSlug;
  const iconStyles = partner?.styles?.iconStyles;
  const iconPadding = iconStyles?.padding;

  const renderCore = useCallback(() => {
    return (
      <>
        {!src && !partner?.logoURL ? (
          <Skeleton
            width={width || 32}
            height={width || height || 32}
            radius={radius || 6}
          />
        ) : (
          <Image
            alt={partner?.title || ""}
            width={width || 32}
            height={width || height || 32}
            radius={iconPadding ? 0 : radius || 6}
            src={src || partner?.iconURL || ""}
            style={{
              backgroundColor: backgroundColor || theme.colors.background[0],
              ...iconStyles,
              // padding: iconStyles?.padding,
            }}
          />
        )}
      </>
    );
  }, [
    backgroundColor,
    height,
    iconPadding,
    iconStyles,
    partner?.iconURL,
    partner?.logoURL,
    partner?.title,
    radius,
    src,
    theme.colors.background,
    width,
  ]);

  if (iconStyles?.padding) {
    console.log("iconStyles", iconStyles);
    return (
      <Flex
        bg={theme.colors.background[0]}
        w={width || 32}
        h={width || height || 32}
        align="center"
        justify="center"
        style={{
          padding: iconStyles.padding,
          borderRadius: iconStyles.borderRadius,
        }}
      >
        {renderCore()}
      </Flex>
    );
  }
  return renderCore();
};
