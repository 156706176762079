import {
  AspectRatio,
  Box,
  Card,
  Group,
  lighten,
  Space,
  Spoiler,
  Stack,
} from "@mantine/core";
import logoLight from "../assets/advize-logo-light.jpg";
import {
  IconChevronLeft,
  IconChevronRight,
  IconExternalLink,
  IconLink,
  IconSchool,
  IconUser,
} from "@tabler/icons-react";
import { AdvizerVideo } from "@/components/AdvizerVideo";
import { Link, useParams } from "react-router-dom";
import { PartnerLogo } from "@/components/PartnerLogo";
import { AdvizerSearchControls } from "@/components/advizerSearchControls/AdvizerSearchControls";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import { useCallback, useEffect, useMemo } from "react";
import { AdvizerSearchDrawer } from "@/components/drawers/AdvizerSearchDrawer";
import { Button } from "@/components/ui/Button";
import {
  useAdvizer,
  useAdvizerQuotes,
  useFilteredAdvizers,
} from "@/store/selectors";
import { AdvizerTag } from "@/components/AdvizerTag";
import { parsePersonalBrandingLinks } from "@/lib/helpers";
import { IAdvizerVideo } from "@/models/AdvizerVideo";
import { useStore } from "@/store";
import { IAdvizerModel } from "@/models/Advizer";
import { Image } from "@/components/ui/Image";
import { Text } from "@/components/ui/Text";
import { Title } from "@/components/ui/Title";
import { Navigation } from "@/services/Navigation";
import { IQuote } from "@/types/ApiTypes";
import { AdvizerQuotes } from "@/components/AdvizerQuotes";
import { ResourceConfigs } from "@/configs/resourceConfigs";

const iconSize = 16;
const iconStyle = {
  width: 24,
};
const itemGroupProps: any = {
  gap: 8,
  flex: 1,
  align: "flex-start",
  justify: "flex-start",
  wrap: "nowrap",
  w: "100%",
  mb: 8,
};

const itemTitleProps: any = {
  titleStyle: "h5",
  c: "text.7",
  ta: "left",
  lh: 1.3,
  m: 0,
  fw: 500,
  w: "100%",
  fontFamily: "League Spartan, sans-serif",
};

export const AdvizerProfile = ({
  standalone,
  advizerIdFromProps,
}: {
  standalone?: boolean;
  advizerIdFromProps?: string;
}) => {
  const { advizerId: advizerIdParam } = useParams();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const { theme } = useStyles();

  const advizerId = advizerIdParam || advizerIdFromProps || "";
  const advizer = useAdvizer(advizerId);

  const { advizers } = useFilteredAdvizers(); // TODO update to have specific selectors
  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );
  const quotes = useAdvizerQuotes(advizerId || "");

  const { nextAdvizerId, prevAdvizerId } = useMemo(() => {
    if (!advizers || advizers.length === 0) {
      return { nextAdvizerId: undefined, prevAdvizerId: undefined };
    }

    const currentIndex = advizers.findIndex(
      (a: IAdvizerModel) => a.id === advizerId,
    );
    const lastIndex = advizers.length - 1;

    return {
      nextAdvizerId:
        currentIndex === lastIndex
          ? advizers[0].id
          : advizers[currentIndex + 1]?.id,
      prevAdvizerId:
        currentIndex === 0
          ? advizers[lastIndex].id
          : advizers[currentIndex - 1]?.id,
    };
  }, [advizerId, advizers]);

  useEffect(() => {
    if (standalone) return;
    const advizerIsInList = !!advizers?.find(
      (a: IAdvizerModel) => a.id === advizerId,
    );

    if (!advizerIsInList && advizers && advizers.length > 0) {
      const firstAdvizerId = advizers[0].id;
      if (!firstAdvizerId) return;
      Navigation.navigate(`/advizers/${firstAdvizerId}/profile`);
    }
  }, [advizerId, advizers, standalone]);

  const renderAdvizerWatch = useCallback(() => {
    return (
      <Stack w="100%" gap={50} p={0}>
        {advizer?.videos
          .filter((video: IAdvizerVideo) => {
            if (activeQuestionId) {
              return video.question?.id === activeQuestionId;
            }
            return true;
          })
          .map((video: IAdvizerVideo, i: number) => {
            const matchingQuote = quotes?.find(
              (quote: IQuote) => quote.videoId === video.id,
            );

            return (
              <Card
                padding="md"
                radius="md"
                bg="transparent"
                w="100%"
                key={video.id}
              >
                <Card.Section pos="relative" h="100%" flex={1}>
                  <AdvizerVideo
                    key={i}
                    videoId={video.id}
                    video={video}
                    questionNumber={video.questionNumber}
                    title={video.title}
                    url={video.url}
                  />
                </Card.Section>
                <Card.Section>
                  <Space h="xs" />
                  <AdvizerQuotes
                    advizer={advizer}
                    activeQuote={matchingQuote}
                  />
                </Card.Section>
              </Card>
            );
          })}
      </Stack>
    );
  }, [activeQuestionId, advizer, quotes]);

  // const PartnerLogos = useMemo(() => {
  //   if (!advizer?.partners || advizer?.partners.length === 0) {
  //     return null;
  //   }
  //   return (
  //     <Group justify="center" gap="xl">
  //       {(advizer?.partners || []).map((partner) => {
  //         return (
  //           <Group key={partner.id}>
  //             <PartnerLogo
  //               width={28}
  //               partnerId={partner.id}
  //               partnerSlug={partner.slug}
  //             />
  //             <Text size="sm" fw={500} c="text.8">
  //               {partner.title}
  //             </Text>
  //           </Group>
  //         );
  //       })}
  //     </Group>
  //   );
  // }, [advizer?.partners]);

  const AdvizerProfileImage = useMemo(() => {
    return (
      <Box
        bg="white"
        p={0}
        bd="1px solid primary.1"
        style={{ borderRadius: "50%" }}
      >
        <AspectRatio ratio={1}>
          <Image
            src={advizer?.profilePictureURL || logoLight}
            w={120}
            radius="50%"
            alt={`Advizer ${advizer?.firstName}'s profile picture`}
          />
        </AspectRatio>
      </Box>
    );
  }, [advizer?.firstName, advizer?.profilePictureURL]);

  const AdvizerNavWithAvatar = useMemo(() => {
    return (
      <Group w="100%" align="center" justify="center" wrap="wrap">
        {!standalone && (
          <Button
            ariaLabel="Go to previous advizer"
            to={`/advizers/${prevAdvizerId}`}
            leftSection={<IconChevronLeft />}
            variant="transparent"
            flex={1}
            c="primary.7"
          >
            Prev
          </Button>
        )}
        {AdvizerProfileImage}
        {!standalone && (
          <Button
            ariaLabel="Go to next advizer"
            to={`/advizers/${nextAdvizerId}`}
            rightSection={<IconChevronRight />}
            variant="transparent"
            flex={1}
            c="primary.7"
          >
            Next
          </Button>
        )}
      </Group>
    );
  }, [nextAdvizerId, prevAdvizerId, AdvizerProfileImage, standalone]);

  if (isLaptopOrBigger && !standalone) {
    return (
      <>
        {!standalone && <AdvizerSearchControls />}
        <Stack
          pl="md"
          pr="md"
          pt={standalone ? 0 : isLaptopOrBigger ? 120 : 90}
          pb={200}
          align={"center"}
          w="100%"
          ta={"left"}
          gap={0}
          maw={1200}
          mx="auto"
          // bg="background.0"
          h="100%"
          style={{ overflow: "auto" }}
          bg={lighten(theme.colors.background[1], 0.5)}
        >
          <Group align="start" justify="center" w="100%">
            <Stack
              pl="md"
              pr="md"
              pb={200}
              align={"center"}
              w="100%"
              ta={"left"}
              flex={1}
              pos="sticky"
              top={0}
            >
              {AdvizerNavWithAvatar}
              <AdvizerProfileCard
                advizerId={advizerId}
                linkToProfilePage={standalone}
              />
            </Stack>
            <Stack w="100%" mx="auto" flex={1}>
              {renderAdvizerWatch()}
            </Stack>
          </Group>
        </Stack>
        {!standalone && <AdvizerSearchDrawer />}
      </>
    );
  }

  return (
    <>
      {!standalone && <AdvizerSearchControls />}
      {/* {renderAdvizerProfile()} */}
      <Stack
        // px="xs"
        pt={standalone ? 0 : isLaptopOrBigger ? 120 : 90}
        pb={200}
        align={"center"}
        w="100%"
        ta={"left"}
        gap={0}
        // maw={700}
        mx="auto"
        // bg="background.0"
        h="100%"
        style={{ overflow: "auto" }}
        bg={lighten(theme.colors.background[1], 0.5)}
      >
        <Stack gap={0} w="100%" maw={550} mx="auto">
          {AdvizerNavWithAvatar}

          <Space h="lg" />

          <AdvizerProfileCard
            advizerId={advizerId}
            linkToProfilePage={standalone}
          />

          <Space h="xl" />

          {renderAdvizerWatch()}

          <Space h="xl" />
        </Stack>
      </Stack>

      {!standalone && <AdvizerSearchDrawer />}
    </>
  );
};

const AdvizerTags = ({
  advizerId,
  justify,
  disabled,
}: {
  advizerId: string;
  justify?: "center" | "flex-start";
  disabled?: boolean;
}) => {
  const advizer = useAdvizer(advizerId);
  return (
    <Group w="100%" gap={8} justify={justify || "flex-start"}>
      {advizer?.tags.map((tag) => (
        <AdvizerTag
          key={tag.id}
          tag={tag}
          size={"md"}
          disableClick={disabled}
        />
      ))}
    </Group>
  );
};

const AdvizerPartners = ({ advizerId }: { advizerId: string }) => {
  const advizer = useAdvizer(advizerId);

  if (!advizer?.partners || advizer?.partners.length === 0) {
    return null;
  }
  return (
    <Group justify="flex-start" gap="md">
      {(advizer?.partners || []).map((partner) => {
        return (
          <Group key={partner.id}>
            <PartnerLogo
              width={28}
              partnerId={partner.id}
              partnerSlug={partner.slug}
            />
            <Text size="sm" fw={500} c="text.8">
              {partner.title}
            </Text>
          </Group>
        );
      })}
    </Group>
  );
};

export const AdvizerProfileCard = ({
  advizerId,
  linkToProfilePage,
  disableTags,
}: {
  advizerId: string;
  linkToProfilePage?: boolean;
  disableTags?: boolean;
}) => {
  const advizer = useAdvizer(advizerId);
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const { theme } = useStyles();
  return (
    <Stack
      gap={0}
      w="100%"
      bg="background.0"
      p={isLaptopOrBigger ? "xl" : "lg"}
      style={{
        borderRadius: 8,
        border: `1px solid ${theme.colors.border[2]}`,
        boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <Stack gap={0} w="100%" align="start">
        <Group align="flex-start" justify="space-between" w="100%" flex={1}>
          <Title order={1} size="h2" c="text.8" lh={1.2} ta="left">
            {advizer?.firstName}
          </Title>
          {linkToProfilePage && (
            <Link
              to={`/advizers/${advizerId}`}
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                fontSize: 14,
              }}
            >
              View profile
              <IconExternalLink size={16} />
            </Link>
          )}
        </Group>

        <Space h="sm" />

        <Stack gap={4} w="100%" align="start">
          <Group {...itemGroupProps}>
            <ResourceConfigs.jobFunction.Icon
              size={iconSize}
              color={theme.colors.text[5]}
              style={iconStyle}
            />
            <Text {...itemTitleProps}>{advizer?.jobTitle}</Text>
          </Group>

          {/* <Space h={4} /> */}

          <Group {...itemGroupProps}>
            <ResourceConfigs.company.Icon
              size={iconSize}
              color={theme.colors.text[5]}
              style={iconStyle}
            />
            <Text {...itemTitleProps}>{advizer?.company}</Text>
          </Group>

          <Group {...itemGroupProps}>
            <IconSchool
              size={iconSize}
              color={theme.colors.text[5]}
              style={iconStyle}
            />
            <Text {...itemTitleProps}>
              {advizer?.undergradSchool}
              {advizer?.gradProgramsRaw && <br />}
              {advizer?.gradProgramsRaw}
            </Text>
          </Group>

          {!!advizer?.personalBrandingLinks && (
            <Group {...itemGroupProps}>
              <IconLink
                size={iconSize}
                color={theme.colors.text[5]}
                style={iconStyle}
              />
              <Text {...itemTitleProps} fw={500}>
                {parsePersonalBrandingLinks(
                  advizer?.personalBrandingLinks || "",
                )}
              </Text>
            </Group>
          )}

          <Group {...itemGroupProps}>
            <IconUser
              size={iconSize}
              color={theme.colors.text[5]}
              style={iconStyle}
            />
            <Spoiler
              maxHeight={80}
              showLabel="Show more"
              hideLabel="Hide"
              fz={14}
              ta="left"
              c="text.7"
              flex={1}
              styles={{
                content: {
                  fontFamily: "League Spartan, sans-serif",
                  fontSize: 16,
                },
                control: {
                  fontWeight: 400,
                  fontFamily: "League Spartan, sans-serif",
                },
              }}
              // lh={1.3}
            >
              {advizer?.bio}
            </Spoiler>
          </Group>
        </Stack>

        <Space h="xs" />
      </Stack>

      {advizer?.partners?.length ? (
        <>
          <Stack align="start" justify="start" gap="xs" w="100%">
            <AdvizerPartners advizerId={advizerId} />
          </Stack>
          <Space h="sm" />
        </>
      ) : null}

      <Space h="sm" />
      <AdvizerTags advizerId={advizerId} disabled={disableTags} />
    </Stack>
  );
};
