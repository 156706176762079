import { onValue, ref, update, off, remove } from "firebase/database";
import { db } from "@/firebase/firebase";
import { getPartners } from "@/store/externalSelectors";
import { StoreService } from "./StoreService";

class RealtimeDatabaseService {
  updateUser(userId: string, data: any) {
    const userRef = ref(db, `users/${userId}`);
    update(userRef, data);
  }

  updateCurrentUser(data: any) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    this.updateUser(activeUser.id, data);
  }

  updateCurrentUserBehaviorData(data: any) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const behaviorRef = ref(db, `users/${activeUser.id}/behavior`);
    update(behaviorRef, data);
  }

  updateCurrentUserUiStateData(data: any) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const uiStateRef = ref(db, `users/${activeUser.id}/uiState`);
    update(uiStateRef, data);
  }

  updateCurrentUserPlaylistData({
    playlistId,
    data,
  }: {
    playlistId: string;
    data: any;
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const playlistsRef = ref(
      db,
      `users/${activeUser.id}/playlists/${playlistId}`,
    );
    update(playlistsRef, data);
  }

  async subscribeToUser(
    userId: string,
    callback: (userData: any) => void,
  ): Promise<any> {
    this.unsubscribeFromUser(userId);
    const userRef = ref(db, `users/${userId}`);
    off(userRef, "value");

    onValue(
      userRef,
      (snapshot) => {
        const userData = snapshot.val();
        callback(userData);
      },
      (error) => {
        console.error("Error subscribing to user", error);
      },
    );
  }

  unsubscribeFromUser(userId: string) {
    const userRef = ref(db, `users/${userId}`);
    off(userRef, "value");
  }

  updateCurrentUserPartner(userId: string, partnerId: string) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const partner = getPartners()?.find((partner) => partner.id === partnerId);

    if (partner) {
      this.updateUser(userId, {
        partnerId,
        partnerSlug: partner?.slug || partner?.title,
      });
    }
  }

  updateCurrentUserPlaylistStepData(params: {
    playlistId: string;
    stepKey: string;
    data: any;
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    console.log("Updating current user playlist step data", {
      activeUser,
      params,
    });

    const playlistsRef = ref(
      db,
      `users/${activeUser.id}/playlistData/playlists/${params.playlistId}/steps/${params.stepKey}`,
    );
    update(playlistsRef, params.data);
  }

  addCurrentUserSubject({
    subjectId,
    subjectData,
  }: {
    subjectId: string;
    subjectData: any;
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const subjectsRef = ref(
      db,
      `users/${activeUser.id}/profileData/subjects/${subjectId}`,
    );
    update(subjectsRef, subjectData);
  }

  removeCurrentUserSubject({ subjectId }: { subjectId: string }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const subjectsRef = ref(
      db,
      `users/${activeUser.id}/profileData/subjects/${subjectId}`,
    );
    remove(subjectsRef);
  }

  addCurrentUserIndustry({
    industryId,
    industryData,
  }: {
    industryId: string;
    industryData: any;
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const industriesRef = ref(
      db,
      `users/${activeUser.id}/profileData/industries/${industryId}`,
    );
    update(industriesRef, industryData);
  }

  removeCurrentUserIndustry({ industryId }: { industryId: string }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const industriesRef = ref(
      db,
      `users/${activeUser.id}/profileData/industries/${industryId}`,
    );
    remove(industriesRef);
  }

  updateCurrentUserRecommendedPlaylists({
    playlistIds,
  }: {
    playlistIds: string[];
  }) {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const recommendedPlaylistsRef = ref(
      db,
      `users/${activeUser.id}/playlistData/recommendedPlaylists`,
    );
    update(
      recommendedPlaylistsRef,
      playlistIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {} as any),
    );
  }

  clearUserRecommendedPlaylists() {
    const activeUser = StoreService.getActiveUser();
    if (!activeUser) return;

    const recommendedPlaylistsRef = ref(
      db,
      `users/${activeUser.id}/playlistData/recommendedPlaylists`,
    );
    remove(recommendedPlaylistsRef);
  }
}

export const RealtimeDatabase = new RealtimeDatabaseService();
