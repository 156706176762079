import { useEffect } from "react";
import {
  useActivePlaylist,
  useActivePlaylistId,
  useActivePlaylistStepKey,
  useFirstStepKeyInPlaylist,
  useIsOnboardingPlaylist,
  useNextIncompleteStepKeyInPlaylist,
  useShouldAutoNavToNextStepInPlaylist,
} from "@/store/selectors/playlistSelectors";
import { Navigation } from "@/services/Navigation";
import { PlaylistService } from "@/services/PlaylistService";
import { useStore } from "@/store";
import { useLocation, useParams } from "react-router-dom";

export const PlaylistLogic = () => {
  const { playlistSlug } = useParams();
  const activePlaylistId = useActivePlaylistId();
  const activePlaylist = useActivePlaylist();
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const { pathname } = useLocation();
  const shouldAutoNavToNextStep = useShouldAutoNavToNextStepInPlaylist();
  const setShowMainNavbar = useStore((state) => state.setShowMainNavbar);
  const updatePlaylistState = useStore(
    (state) => state.playlists.updatePlaylistsState,
  );
  const setMostRecentPathname = useStore(
    (state) => state.setMostRecentPathname,
  );
  const nextIncompleteStepKey = useNextIncompleteStepKeyInPlaylist();
  const firstStepKeyInPlaylist = useFirstStepKeyInPlaylist();
  const isOnboardingPlaylist = useIsOnboardingPlaylist();
  const isRootUrlPath = pathname === "/";

  useEffect(() => {
    if (isRootUrlPath) {
      updatePlaylistState({
        activePlaylistId: "onboarding",
        activePlaylistStepKey: "welcome",
      });
    }
  }, [isRootUrlPath, updatePlaylistState]);

  // fetch active playlist
  useEffect(() => {
    async function fetchPlaylistConfig() {
      const filePath = `/playlists/${activePlaylistId}.json`;
      const playlistResponse = await fetch(filePath);
      const playlist = await playlistResponse.json();
      updatePlaylistState({ activePlaylist: playlist });
    }

    if (
      activePlaylistId &&
      (!activePlaylist || activePlaylistId !== activePlaylist?.id)
    ) {
      fetchPlaylistConfig();
    }
  }, [activePlaylist, activePlaylistId, updatePlaylistState]);

  // get all playlists on load
  useEffect(() => {
    async function initPlaylists() {
      await PlaylistService.getAllPlaylists();
    }
    initPlaylists();
  }, []);

  useEffect(() => {
    if (playlistSlug !== activePlaylistId) {
      updatePlaylistState({ activePlaylist: null });
    }
  }, [activePlaylistId, playlistSlug, updatePlaylistState]);

  useEffect(() => {
    if (playlistSlug) {
      updatePlaylistState({ activePlaylistId: playlistSlug });
    } else if (!isRootUrlPath) {
      updatePlaylistState({ activePlaylistId: null, activePlaylist: null });
    }
  }, [isRootUrlPath, playlistSlug, updatePlaylistState]);

  useEffect(() => {
    setShowMainNavbar(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlaylistId, activePlaylistStepKey]);

  useEffect(() => {
    if (pathname.includes("/playlist")) {
      const fullPathname = `${pathname}${location.search}`;
      setMostRecentPathname(fullPathname);
    }
  }, [pathname, setMostRecentPathname]);

  /*===========================================
  Auto navigate when necessary
  ===========================================*/

  useEffect(() => {
    if (
      playlistSlug &&
      activePlaylist &&
      activePlaylistId &&
      !activePlaylistStepKey &&
      (nextIncompleteStepKey || firstStepKeyInPlaylist)
    ) {
      Navigation.navToPlaylistAndStep({
        playlistId: activePlaylistId,
        step: isOnboardingPlaylist
          ? firstStepKeyInPlaylist || nextIncompleteStepKey
          : nextIncompleteStepKey || firstStepKeyInPlaylist,
      });
    }
  }, [
    activePlaylist,
    activePlaylistId,
    activePlaylistStepKey,
    firstStepKeyInPlaylist,
    isOnboardingPlaylist,
    nextIncompleteStepKey,
    playlistSlug,
  ]);

  useEffect(() => {
    if (activePlaylistId && shouldAutoNavToNextStep && !activePlaylistStepKey) {
      // Navigation.navToPlaylistAndStep({
      //   playlistId: activePlaylistId,
      //   step: nextIncompleteStepKey,
      // });
    }
    // else if (activePlaylistId && !activePlaylistStepKey && firstStepKeyInPlaylist) {
    //   Navigation.navToPlaylistAndStep({
    //     playlistId: activePlaylistId,
    //     step: firstStepKeyInPlaylist,
    //   });
    // }
  }, [
    activePlaylistId,
    shouldAutoNavToNextStep,
    nextIncompleteStepKey,
    activePlaylistStepKey,
    firstStepKeyInPlaylist,
  ]);

  /*===========================================
  Auto navigate if step is not in the playlist
  ===========================================*/

  useEffect(() => {
    if (
      activePlaylistStepKey &&
      activePlaylist?.steps &&
      !activePlaylist?.steps[activePlaylistStepKey]
    ) {
      Navigation.removeQueryParam("step");
    }
  }, [activePlaylist, activePlaylistStepKey]);

  // useEffect(() => {
  //   if (
  //     activePlaylistStepKey &&
  //     activePlaylist &&
  //     !activePlaylist?.steps[activePlaylistStepKey]
  //   ) {
  //     Navigation.removeQueryParam("step");
  //   }
  // }, [
  //   activePlaylist,
  //   activePlaylist?.steps,
  //   activePlaylistStep,
  //   activePlaylistStepKey,
  // ]);

  // useEffect(() => {
  //   if (shouldAutoNavToNextStep && !activePlaylistStepKey && activePlaylist) {
  //     Navigation.navToPlaylistAndStep({
  //       playlistId: activePlaylistId,
  //       step: nextIncompleteStepKey,
  //     });
  //     return;
  //   }

  //   if (activePlaylistId && !activePlaylistStepKey && firstStepKeyInPlaylist) {
  //     // console.log("222", {
  //     //   activePlaylistId,
  //     //   activePlaylistStepKey,
  //     //   firstStepKeyInPlaylist,
  //     // });
  //     Navigation.navToPlaylistAndStep({
  //       playlistId: activePlaylistId,
  //       step: firstStepKeyInPlaylist,
  //     });
  //   }
  // }, [
  //   activePlaylistStepKey,
  //   shouldAutoNavToNextStep,
  //   activePlaylist,
  //   activePlaylistId,
  //   nextIncompleteStepKey,
  //   firstStepKeyInPlaylist,
  // ]);

  // useEffect(() => {
  //   if (isOnboardingPlaylist) return;
  //   if (
  //     activePlaylistId &&
  //     activePlaylistStepKey &&
  //     Object.keys(stepCompletionStatuses).length > 0
  //   ) {
  //     // console.log("333", {
  //     //   activePlaylistId,
  //     //   activePlaylistStepKey,
  //     //   stepCompletionStatuses,
  //     //   nextIncompleteStepKey,
  //     // });
  //     const isStepComplete = stepCompletionStatuses?.[activePlaylistStepKey];
  //     if (!isStepComplete && nextIncompleteStepKey !== activePlaylistStepKey) {
  //       Navigation.navToPlaylistAndStep({
  //         playlistId: activePlaylistId,
  //         step: nextIncompleteStepKey,
  //       });
  //     }
  //   }
  // }, [
  //   activePlaylistId,
  //   activePlaylistStepKey,
  //   activePlaylistStepKeyRaw,
  //   isOnboardingPlaylist,
  //   nextIncompleteStepKey,
  //   stepCompletionStatuses,
  // ]);

  // useEffect(() => {
  //   PlaylistService.updateUserActivePlaylistId(activePlaylistId);
  // }, [activePlaylistId]);

  // useEffect(() => {
  //   PlaylistService.updateUserActivePlaylistStepKey(activePlaylistStepKey);

  //   setDidScrollDown(false);
  // }, [activePlaylistStepKey, setDidScrollDown]);

  return null;
};
