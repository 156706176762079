import {
  useActivePlaylistId,
  useActivePlaylistStepKey,
  usePlaylistVideo,
} from "@/store/selectors/playlistSelectors";
import { AdvizerVideo } from "../AdvizerVideo";
import { Group, Space, Stack } from "@mantine/core";
import { useAdvizer, useAdvizerQuotes } from "@/store/selectors";
import { useMemo } from "react";
import { AdvizerProfileCard } from "@/pages/AdvizerProfilePage";
import { AdvizerQuotes } from "../AdvizerQuotes";
import { PlaylistService } from "@/services/PlaylistService";

export const PlaylistVideo = ({
  advizerId,
  videoQuestionNumber,
}: {
  advizerId: string;
  videoQuestionNumber: number;
}) => {
  const video = usePlaylistVideo({ advizerId, videoQuestionNumber });
  const advizer = useAdvizer(advizerId);
  const quotes = useAdvizerQuotes(advizerId || "");
  const activePlaylistId = useActivePlaylistId();
  const activePlaylistStepKey = useActivePlaylistStepKey();

  const matchingQuote = useMemo(() => {
    return quotes.find((quote) => quote.videoId === video?.id);
  }, [quotes, video?.id]);

  if (!video) return null;

  return (
    <Stack gap={0} pt="xl">
      <AdvizerProfileCard
        advizerId={advizerId}
        linkToProfilePage
        disableTags={true}
      />

      <Space h="lg" />

      <Group
        style={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          overflow: "hidden",
        }}
        p={0}
      >
        <AdvizerVideo
          isPlaylistVideo
          videoId={video.id}
          video={video}
          title={video.title}
          url={video.url}
          questionNumber={video.questionNumber}
          light={false}
          onPercentProgress={(percentProgress: number) => {
            console.log("percentProgress", percentProgress);
            if (!activePlaylistId || !activePlaylistStepKey) return;
            PlaylistService.updateUserPlaylistVideoStepData({
              playlistId: activePlaylistId,
              stepKey: activePlaylistStepKey,
              data: { percentProgress },
            });
          }}
          onProgressEvery5Seconds={() => {
            console.log("onProgressEvery5Seconds");
            if (!activePlaylistId || !activePlaylistStepKey) return;
            PlaylistService.updateUserPlaylistVideoStepData({
              playlistId: activePlaylistId,
              stepKey: activePlaylistStepKey,
              data: { secondsToAdd: 5 },
            });
            // console.log("secondsWatched", secondsWatched);
          }}
        />
        {advizer && (
          <AdvizerQuotes advizer={advizer} activeQuote={matchingQuote} />
        )}
      </Group>
    </Stack>
  );
};
