import { useStore } from "@/store";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Routing = () => {
  const { navToPath, setNavToPath } = useStore((state) => state.navigation);
  const navigate = useNavigate();
  const setShowMainNavbarRaw = useStore((state) => state.setShowMainNavbar);

  useEffect(() => {
    if (navToPath) {
      console.log("NAV TO PATH", navToPath);
      navigate(navToPath);
      setNavToPath(null);
      setShowMainNavbarRaw(true);
    }
  }, [navToPath, setNavToPath, navigate, setShowMainNavbarRaw]);

  return null;
};
