import { useCallback, useMemo, useState } from "react";
import { Box, Button, Group, Space, Stack } from "@mantine/core";
import { TextInput } from "@/components/ui/TextInput";
import { IconPlaylist, IconSearch } from "@tabler/icons-react";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import {
  useAllPlaylists,
  useRecommendedPlaylists,
} from "@/store/selectors/playlistSelectors";
import { PlaylistCard } from "@/components/playlists/PlaylistCard";
import { Navigation } from "@/services/Navigation";
import { Text } from "@/components/ui/Text";
import { FixedSizeList } from "react-window";
import { AutoSizer } from "react-virtualized";
import { useValueFromBreakpoint } from "@/hooks";
import { Analytics } from "@/services/Analytics";

export function PlaylistLibrary() {
  const [search, setSearch] = useState("");
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const allPlaylists = useAllPlaylists();
  const headerHeight = useStyles().dimensions.headerHeight;
  const recommendedPlaylists = useRecommendedPlaylists();

  const playlistGap = 32;

  const playlistCardHeight = useValueFromBreakpoint({
    mobile: 570,
    tablet: 500,
    laptop: 500,
    desktop: 500,
  });

  const maxPlaylistCardWidth = useValueFromBreakpoint({
    mobile: 500,
    tablet: 650,
    laptop: 700,
    desktop: 700,
  });

  const maxAdvizerListHeight = useValueFromBreakpoint({
    mobile: 120,
    tablet: 100,
    laptop: 100,
    desktop: 100,
  });

  const maxSpoilerHeight = useValueFromBreakpoint({
    mobile: 100,
    tablet: 80,
    laptop: 75,
    desktop: 75,
  });

  const advizerQuoteLineClamp = useValueFromBreakpoint({
    mobile: 3,
    tablet: 2,
    laptop: 2,
    desktop: 2,
  });

  const visiblePlaylists = useMemo(() => {
    return allPlaylists
      .filter((playlist: any) => {
        const uniqIdStuff = Object.values(playlist.config?.uniqId || {})
          .map((arr: any) => (Array.isArray(arr) ? arr.join(" ") : ""))
          .join(" ");

        const textToSearch = `${playlist.id} ${playlist.title} ${playlist.description} ${uniqIdStuff}`;
        return textToSearch.toLowerCase().includes(search.toLowerCase());
      })
      .sort((a, b) => {
        const aIsRecommended = recommendedPlaylists?.[a.id];
        const bIsRecommended = recommendedPlaylists?.[b.id];
        if (aIsRecommended && !bIsRecommended) return -1;
        if (!aIsRecommended && bIsRecommended) return 1;
        return 0;
      });
  }, [allPlaylists, recommendedPlaylists, search]);

  const rowRenderer = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      const playlist = visiblePlaylists[index];
      return (
        <Stack
          w="100%"
          style={style}
          key={playlist.id}
          // h={playlistCardHeight}
          // maw={maxPlaylistCardWidth}
          align="center"
          pb={playlistGap}
        >
          <PlaylistCard
            playlistId={playlist.id}
            maxAdvizerListHeight={maxAdvizerListHeight}
            maxSpoilerHeight={maxSpoilerHeight}
            advizerQuoteLineClamp={advizerQuoteLineClamp}
            maxWidth={maxPlaylistCardWidth}
            height={playlistCardHeight}
          />
        </Stack>
      );
    },
    [
      maxAdvizerListHeight,
      maxPlaylistCardWidth,
      playlistCardHeight,
      visiblePlaylists,
      maxSpoilerHeight,
      advizerQuoteLineClamp,
    ],
  );

  const renderListOfPlaylists = useCallback(() => {
    return (
      <AutoSizer>
        {({ width, height }) => {
          return (
            <FixedSizeList
              width={width}
              height={height}
              itemCount={visiblePlaylists.length}
              itemSize={playlistCardHeight}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // paddingBottom: 1000
                // width: "100%",
              }}
            >
              {rowRenderer}
            </FixedSizeList>
          );
        }}
      </AutoSizer>
    );
  }, [rowRenderer, visiblePlaylists, playlistCardHeight]);

  return (
    <Box
      pt={headerHeight}
      bg="background.1"
      h="100vh"
      w="100%"
      style={{ overflow: "auto" }}
      // align="center"
    >
      <Stack
        w="100%"
        mx="auto"
        p="md"
        pb={0}
        align="center"
        // maw={1200}
        // style={{ borderBottom: `1px solid ${theme.colors.border[2]}` }}
      >
        <Group w="100%" justify="center" wrap="wrap">
          <Button
            radius="xl"
            size="sm"
            variant="filled"
            px="md"
            leftSection={<IconPlaylist size={18} />}
            color="primary"
            onClick={() => {
              Navigation.navToPlaylistAndStep({
                playlistId: "onboarding",
                step: "select-subjects",
              });
              Analytics.click({
                action: "Get Recommended Playlists from Playlists Library",
              });
            }}
          >
            {isLaptopOrBigger ? "Get Recommended Playlists" : "Get Recs"}
          </Button>
          <TextInput
            id="playlistLibrarySearch"
            value={search}
            // onChange={(e) => setSearch(e.target.value)}
            placeholder="Type to search"
            size={isLaptopOrBigger ? "sm" : "sm"}
            radius="xl"
            leftSection={<IconSearch size={18} />}
            miw={250}
            onClear={() => {
              setSearch("");
            }}
            onDebouncedChange={(value) => {
              console.log({ value });
              setSearch(value);

              if (value.length > 0) {
                Analytics.filterPlaylists({
                  filterType: "search",
                  filterValue: value,
                });
              }
            }}
          />
          {isLaptopOrBigger && (
            <Text titleStyle="h5" fw={500}>
              {isLaptopOrBigger ? "Showing" : ""} {visiblePlaylists.length}{" "}
              playlist{visiblePlaylists.length > 1 ? "s" : ""}
            </Text>
          )}
        </Group>
      </Stack>
      <Space h="md" />
      <Box w="100%" h="100%" mx="auto" pt={0}>
        {renderListOfPlaylists()}
      </Box>
      {/* <Group
        gap="xl"
        w="100%"
        wrap="wrap"
        justify="center"
        style={{ alignItems: "stretch" }} // Ensure all items have the same height
      > */}
      {/* {visiblePlaylists.map((playlist: any) => (
          <Stack key={playlist.id} w={600} maw="100%" justify="flex-start">
            <PlaylistCard playlistId={playlist.id} />
          </Stack>
        ))} */}
      {/* </Group> */}
    </Box>
  );
}
