import { ResourceConfigs } from "@/configs/resourceConfigs";
import {
  IconCoffee,
  IconPlaylist,
  IconRoad,
  IconSearch,
  IconTools,
  IconUsersPlus,
} from "@tabler/icons-react";

type IPlaylistOutcomeConfig = {
  title: string;
  Icon: React.ElementType;
  color: string;
  requiredProfileDataSteps?: string[];
};

export const PLAYLIST_OUTCOMES: Record<string, IPlaylistOutcomeConfig> = {
  "narrow-down-career-options": {
    title: "Narrow down my career options",
    Icon: IconRoad,
    color: ResourceConfigs.subject.color,
    requiredProfileDataSteps: [
      "select-subjects",
      "select-industries",
      "custom-request",
    ],
  },
  "industries-for-me": {
    title: "Get an inside look at industries I want to work in",
    Icon: ResourceConfigs.industry.Icon,
    color: ResourceConfigs.industry.color,
    requiredProfileDataSteps: ["select-subjects"],
  },
  "jobs-for-me": {
    title: "Find job types that are right for me",
    Icon: ResourceConfigs.jobFunction.Icon,
    color: ResourceConfigs.jobFunction.color,
    requiredProfileDataSteps: ["select-subjects"],
  },
  "find-advizers-for-me": {
    title: "Match with Advizers who can help me most",
    Icon: IconUsersPlus,
    color: "blue",
    requiredProfileDataSteps: ["select-subjects"],
  },
  "skills-I-need": {
    title: "Uncover what skills I need to thrive in my future career",
    Icon: IconTools,
    color: "green",
    requiredProfileDataSteps: ["select-subjects"],
  },
  "day-in-the-life": {
    title: "Understand what a day in the life is like for real professionals",
    Icon: IconCoffee,
    color: "orange",
    requiredProfileDataSteps: ["select-subjects"],
  },
  "browse-advizers": {
    title: "Filter and search Advizers on my own",
    Icon: IconSearch,
    color: "lime",
    requiredProfileDataSteps: ["select-subjects"],
  },
  "browse-playlists": {
    title: "Browse playlists on my own",
    Icon: IconPlaylist,
    color: "violet",
    requiredProfileDataSteps: ["select-subjects"],
  },
};
