import {
  alpha,
  Button,
  ChipGroup,
  Group,
  SegmentedControl,
  Space,
  Stack,
  Tabs,
  TextInput,
} from "@mantine/core";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import {
  useActiveUserPartner,
  useCheckIsFilterOptionSelected,
  useCompanyFilterOptions,
  useIndustryFilterOptions,
  useJobFunctionFilterOptions,
  useSelectedAdvizerSearchFilters,
  useShowPartnerToggle,
  useSubjectFilterOptions,
  useToggleAdvizerSearchFilterOption,
  useTraitFilterOptions,
} from "@/store/selectors";
import { PartnerLogo } from "@/components/PartnerLogo";
import { Chip } from "@/components/ui/Chip";
import { IResourceConfig, ResourceConfigs } from "@/configs/resourceConfigs";
import { IAdvizerTagTypes } from "@/components/AdvizerTag";
import { IconCheck, IconSearch } from "@tabler/icons-react";
import { IAdvizerSearchFilterKeys } from "@/types/StoreTypes";
import { Analytics } from "@/services/Analytics";
import { Text } from "@/components/ui/Text";

import { useStore } from "@/store";

export const drawerId = "advizerSearchDrawer";

interface ITabConfig {
  value: IAdvizerTagTypes;
  filterKey: IAdvizerSearchFilterKeys;
  label?: string;
  tabTitle?: ReactNode;
  onClick?: (option: { id: string; label: string }) => void;
  options?: { id: string; label: string }[];
  selectedOptions?: { [key: string]: boolean };
  setSelectedOptions?: any;
  content?: ReactNode;
  count?: number;
}

export const AdvizerSearchFilters = () => {
  const [activeTab, setActiveTab] = useState<string | null>("subject");
  const { theme } = useStyles();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const { headerHeight } = useStyles().dimensions;

  const partner = useActiveUserPartner();
  const showPartnerToggle = useShowPartnerToggle();
  const activeFilterPartnerId = useStore(
    (state) => state.advizerSearch.activeFilterPartnerId,
  );
  const setActiveFilterPartnerId = useStore(
    (state) => state.advizerSearch.setActiveFilterPartnerId,
  );

  const { subjects } = useSubjectFilterOptions();
  const { jobFunctions } = useJobFunctionFilterOptions();
  const { industries } = useIndustryFilterOptions();
  const { traits } = useTraitFilterOptions();
  const { companies } = useCompanyFilterOptions();
  const {
    companies: selectedCompanies,
    industries: selectedIndustries,
    subjects: selectedSubjects,
    traits: selectedTraits,
    jobFunctions: selectedJobFunctions,
  } = useSelectedAdvizerSearchFilters();

  const companyColor = ResourceConfigs.company.color;

  const toggleFilterOption = useToggleAdvizerSearchFilterOption();

  const handleToggleFilterOption = useCallback(
    (filterKey: IAdvizerSearchFilterKeys, resourceId: string) => {
      const optionsByType = {
        industries,
        jobFunctions,
        subjects,
        traits,
        companies,
      };

      const selectedOptionsByType = {
        industries: selectedIndustries,
        jobFunctions: selectedJobFunctions,
        subjects: selectedSubjects,
        traits: selectedTraits,
        companies: selectedCompanies,
      };

      toggleFilterOption(filterKey, resourceId);

      const isSelected = selectedOptionsByType?.[filterKey]?.[resourceId];

      if (!isSelected) {
        Analytics.filterAdvizers({
          filterType: filterKey,
          filterValue:
            ((optionsByType[filterKey] || []) as any[]).find(
              (option: any) => option.id === resourceId,
            )?.title || resourceId,
          resourceId,
        });
      }
    },
    [
      companies,
      industries,
      jobFunctions,
      selectedCompanies,
      selectedIndustries,
      selectedJobFunctions,
      selectedSubjects,
      selectedTraits,
      subjects,
      toggleFilterOption,
      traits,
    ],
  );

  const [companySearchText, setCompanySearchText] = useState("");

  const tabs: ITabConfig[] = useMemo(
    () => [
      {
        value: "industry",
        filterKey: "industries",
        label: "Industry",
        count: Object.keys(selectedIndustries).length,
        onClick: (option: { id: string; label: string }) => {
          handleToggleFilterOption("industries", option.id);
        },
        tabTitle: (
          <span>
            Pick <u>industries</u> you're interested in
          </span>
        ),
        options: [...industries].map((industry) => ({
          id: industry.id,
          label: industry.title,
        })),
      },
      {
        value: "jobFunction",
        filterKey: "jobFunctions",
        label: "Role",
        count: Object.keys(selectedJobFunctions).length,
        onClick: (option: { id: string; label: string }) => {
          handleToggleFilterOption("jobFunctions", option.id);
        },
        tabTitle: (
          <span>
            Pick <u>job roles</u> you're curious about
          </span>
        ),
        options: [...jobFunctions].map((jobFunction) => ({
          id: jobFunction.id,
          label: jobFunction.title,
        })),
      },
      {
        value: "subject",
        filterKey: "subjects",
        label: "Major",
        count: Object.keys(selectedSubjects).length,
        onClick: (option: { id: string; label: string }) => {
          handleToggleFilterOption("subjects", option.id);
        },
        tabTitle: (
          <span>
            Pick <u>undergrad majors</u> like yours
          </span>
        ),
        options: [...subjects].map((subject) => ({
          id: subject.id,
          label: subject.title,
        })),
        // selectedOptions: checkedMajors,
        // setSelectedOptions: setCheckedMajors,
      },
      {
        value: "trait",
        filterKey: "traits",
        label: "Traits",
        count: Object.keys(selectedTraits).length,
        onClick: (option: { id: string; label: string }) => {
          handleToggleFilterOption("traits", option.id);
        },
        tabTitle: (
          <span>
            Pick <u>traits</u> you relate to
          </span>
        ),
        options: [...traits].map((trait) => ({
          id: trait.id,
          label: trait.title,
        })),
      },
      {
        value: "company",
        filterKey: "companies",
        label: "Company",
        count: Object.keys(selectedCompanies).length,
        tabTitle: (
          <span>
            Pick <u>companies</u> you imagine working for
          </span>
        ),

        content: (
          <Stack gap={0} px="xs">
            <Space h="xs" />
            <Group px="xs">
              <TextInput
                value={companySearchText}
                onChange={(e) => setCompanySearchText(e.target.value)}
                placeholder="Type to search companies"
                radius="sm"
                size="md"
                flex={1}
                leftSection={<IconSearch size={18} />}
              />
            </Group>
            <Space h="xl" />
            {companies
              .filter((company: any) =>
                company.toLowerCase().includes(companySearchText.toLowerCase()),
              )
              .map((company: any) => {
                const isSelected = selectedCompanies[company];
                return (
                  <Button
                    py="xs"
                    px="md"
                    key={company}
                    unstyled
                    ta="left"
                    onClick={() =>
                      handleToggleFilterOption("companies", company)
                    }
                    style={{
                      cursor: "pointer",
                      borderBottom: `1px solid ${theme.colors.border[2]}`,
                    }}
                  >
                    <Group justify="space-between">
                      <Text
                        fw={isSelected ? 600 : 500}
                        c={isSelected ? `${companyColor}.7` : `text.7`}
                        flex={1}
                      >
                        {company}
                      </Text>
                      {isSelected && <IconCheck size={20} />}
                    </Group>
                  </Button>
                );
              })}
          </Stack>
        ),
      },
    ],
    [
      selectedIndustries,
      industries,
      selectedJobFunctions,
      jobFunctions,
      selectedSubjects,
      subjects,
      selectedTraits,
      traits,
      selectedCompanies,
      companySearchText,
      companies,
      handleToggleFilterOption,
      theme.colors.border,
      companyColor,
    ],
  );

  const renderTabsMenu = useCallback(() => {
    return (
      <Tabs.List bd={0}>
        {tabs.map((tab) => {
          const resourceConfig = ResourceConfigs[tab.value];
          const isActive = tab.value === activeTab;

          return (
            <Tabs.Tab
              value={tab.value}
              key={tab.value}
              px="sm"
              py="xs"
              flex={1}
              onClick={() => setActiveTab(tab.value)}
              bg="background.0"
              style={{
                borderRadius: 0,
                borderTop: isActive
                  ? `2px solid ${theme.colors[resourceConfig.color][2]}`
                  : `2px solid ${theme.colors[resourceConfig.color][0]}`,
              }}
            >
              <Stack gap={4} justify="center" align="center">
                {tab.count ? (
                  <Text
                    titleStyle="h2"
                    lh={1}
                    c={theme.colors[resourceConfig.color][7]}
                  >
                    {tab.count}
                  </Text>
                ) : (
                  <resourceConfig.Icon
                    size={22}
                    color={
                      isActive
                        ? theme.colors[resourceConfig.color][7]
                        : theme.colors.background[5]
                    }
                  />
                )}
                <Text
                  size="sm"
                  fw={isActive ? 700 : 500}
                  c={
                    isActive || tab.count
                      ? theme.colors[resourceConfig.color][7]
                      : theme.colors.text[7]
                  }
                >
                  {tab.label}
                </Text>
              </Stack>
            </Tabs.Tab>
          );
        })}
      </Tabs.List>
    );
  }, [activeTab, tabs, theme.colors]);

  const renderTabOptions = useCallback(({ tab }: { tab: ITabConfig }) => {
    const resourceConfig = ResourceConfigs[tab.value];
    return tab.options?.map((option: { id: string; label: string }) => {
      return (
        <FilterOptionChip
          key={option.id}
          tab={tab}
          option={option}
          resourceConfig={resourceConfig}
        />
      );
    });
  }, []);

  const renderSingleTabContent = useCallback(
    ({ tab }: { tab: ITabConfig }) => {
      if (tab.value !== activeTab) {
        return null;
      }

      const resourceConfig = ResourceConfigs[tab.value];

      return (
        <Tabs.Panel
          key={tab.value}
          value={tab.value}
          bg="background.0"
          pb={100}
          style={{
            overflow: "scroll",
            maxHeight: "100%",
            flex: 1,
          }}
        >
          <Text
            titleStyle="h4"
            fw={700}
            p="xs"
            pt="md"
            ta="center"
            w="100%"
            c={theme.colors[resourceConfig.color][7]}
          >
            {tab.tabTitle}
          </Text>
          {tab.content ? (
            tab.content
          ) : (
            <ChipGroup>
              <Group
                gap={isLaptopOrBigger ? "md" : "sm"}
                p="md"
                justify="flex-start"
              >
                {renderTabOptions({
                  tab,
                })}
              </Group>
            </ChipGroup>
          )}
        </Tabs.Panel>
      );
    },
    [activeTab, isLaptopOrBigger, renderTabOptions, theme.colors],
  );

  const renderTabs = useCallback(() => {
    return (
      <Tabs
        value={activeTab}
        color="primary.4"
        onChange={(value) => setActiveTab(value)}
        inverted
        w="100%"
        // bg="background.0"
        style={{
          [isLaptopOrBigger ? "borderBottom" : "borderBottom"]:
            `1px solid ${theme.colors.border[2]}`,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        {renderTabsMenu()}
        {tabs.map((tab) => {
          return renderSingleTabContent({ tab });
        })}
      </Tabs>
    );
  }, [
    activeTab,
    isLaptopOrBigger,
    renderSingleTabContent,
    renderTabsMenu,
    tabs,
    theme.colors.border,
  ]);

  const renderPartnerToggle = useCallback(() => {
    if (!showPartnerToggle) {
      return null;
    }
    return (
      <Group
        w="100%"
        p={isLaptopOrBigger ? 8 : 2}
        style={{ borderBottom: `1px solid ${theme.colors.border[2]}` }}
        bg="transparent"
        h={headerHeight - 2}
      >
        <SegmentedControl
          fullWidth
          w="100%"
          size={isLaptopOrBigger ? "md" : "md"}
          fw={700}
          p={isLaptopOrBigger ? 0 : "xs"}
          color="primary.8"
          value={activeFilterPartnerId ? "partner" : "all"}
          onChange={(value) => {
            if (value === "partner") {
              setActiveFilterPartnerId(partner.id);
            } else {
              setActiveFilterPartnerId(null);
            }
          }}
          data={[
            {
              value: "all",
              label: (
                <Text c={activeFilterPartnerId ? "text.6" : "white"} fw={700}>
                  All Advizers
                </Text>
              ),
            },
            {
              value: "partner",
              label: (
                <Group w="100%" align="center" justify="center">
                  <PartnerLogo
                    partnerId={partner?.id}
                    width={isLaptopOrBigger ? 26 : 24}
                    disableClick
                    backgroundColor="background.0"
                    noLink
                  />
                  <Text c={activeFilterPartnerId ? "white" : "text.6"} fw={700}>
                    Alums only
                  </Text>
                </Group>
              ),
            },
          ]}
        />
      </Group>
    );
  }, [
    showPartnerToggle,
    isLaptopOrBigger,
    theme.colors.border,
    headerHeight,
    activeFilterPartnerId,
    partner?.id,
    setActiveFilterPartnerId,
  ]);

  return (
    <Stack
      gap={0}
      flex={1}
      h={isLaptopOrBigger ? "100%" : "100vh"}
      style={{
        overflow: "auto",
        overflowX: "auto",
        borderLeft: `2px solid ${theme.colors.background[3]}`,
      }}
    >
      {renderPartnerToggle()}
      {renderTabs()}
    </Stack>
  );
};

export function FilterOptionChip({
  tab,
  option,
  resourceConfig,
  size = "sm",
  checked = false,
}: {
  tab: ITabConfig;
  option: { id: string; label: string };
  resourceConfig: IResourceConfig;
  size?: "xs" | "sm" | "md" | "lg";
  checked?: boolean;
}) {
  const { theme } = useStyles();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isCheckedStore = useCheckIsFilterOptionSelected({
    filterKey: tab.filterKey,
    resourceId: option.id,
  });
  const isChecked = checked || isCheckedStore;
  const maxChipLength = 40;

  return (
    <Chip
      key={option.id}
      variant="light"
      size={size || (isLaptopOrBigger ? "sm" : "md")}
      ariaLabel={option.label}
      color={theme.colors[resourceConfig.color][4]}
      checked={isChecked}
      onClick={() => {
        tab.onClick?.(option);
      }}
      styles={{
        label: {
          paddingLeft: 12,
          paddingRight: 12,
          color: isChecked
            ? theme.colors[resourceConfig.color][9]
            : theme.colors.text[9],
          backgroundColor: isChecked
            ? theme.colors[resourceConfig.color][1]
            : alpha(theme.colors[resourceConfig.color][0], 0.8),
          fontWeight: isChecked ? 600 : 500,
          // border: `1px solid ${theme.colors[resourceConfig.color][4]}`,
        },
      }}
    >
      {option.label.length > maxChipLength
        ? option.label.slice(0, maxChipLength) + "..."
        : option.label}
    </Chip>
  );
}
