import {
  IconBrain,
  IconBrandSlack,
  IconHelp,
  IconHome,
  IconInfoCircle,
  IconPlaylist,
  IconSearch,
} from "@tabler/icons-react";
import { PartnerLogo } from "@/components/PartnerLogo";
import { config } from "@/config";

export const NAV_ITEMS = [
  {
    href: "/",
    label: "Home",
    Icon: IconHome,
    showOnDesktopNavbar: false,
  },
  {
    href: "/playlists",
    label: "Playlists",
    Icon: IconPlaylist,
    showOnDesktopNavbar: true,
    isNew: true,
  },
  {
    href: "/explore",
    label: "Advizers",
    Icon: IconSearch,
    showOnDesktopNavbar: true,
  },
  {
    label: "Alum Advizers",
    hide: (partnerId: string, showPartnerToggle?: boolean) =>
      !partnerId || !showPartnerToggle,
    CustomIcon: (partnerId: string) => (
      <PartnerLogo width={26} disableClick partnerId={partnerId} />
    ),
  },
  {
    href: "https://www.advizehub.com/insights",
    target: "_blank",
    label: "Blog",
    Icon: IconBrain,
  },
  {
    href: config.supportEmailLink,
    label: "Support",
    Icon: IconHelp,
    // target: "_blank",
    // dontClose: true,
    showOnDesktopNavbar: false,
  },
  {
    href: config.slackInviteLink,
    label: "Join Slack",
    Icon: IconBrandSlack,
    target: "_blank",
    dontClose: true,
    showOnDesktopNavbar: false,
  },
  {
    href: "https://www.advizehub.com/about",
    label: "About Advize",
    Icon: IconInfoCircle,
  },
  {},
];
