import { IAdvizerModel } from "@/models/Advizer";
import { IQuote } from "@/types/ApiTypes";
import { Group, lighten, Space, Stack } from "@mantine/core";
import { Text } from "./ui/Text";
import { useStyles } from "@/styles/useStyles";

export const AdvizerQuotes = (props: {
  advizer?: IAdvizerModel;
  activeQuote?: IQuote;
  lineClamp?: number;
  withAdvizerName?: boolean;
  maxHeight?: number;
}) => {
  const {
    advizer,
    activeQuote,
    lineClamp = 2,
    withAdvizerName = false,
    maxHeight,
  } = props;
  const { theme } = useStyles();

  if (!advizer || !activeQuote?.snippet) {
    return null;
  }

  return (
    <Stack gap={0} w="100%">
      <Group
        p="sm"
        h={maxHeight || undefined}
        w="100%"
        bg={
          withAdvizerName
            ? "transparent"
            : lighten(theme.colors?.background[1], 0.5)
        }
        align="start"
        style={{
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: `3px solid ${theme.colors?.primary[3]}`,
        }}
      >
        <Stack gap={0} w="100%">
          <Text
            c="text.9"
            titleStyle="h4"
            fw={400}
            lh={1.3}
            size={withAdvizerName ? "md" : "md"}
            lineClamp={lineClamp}
            ta="left"
            style={{ letterSpacing: 0.2 }}
            w="100%"
          >
            "{activeQuote?.snippet}"
          </Text>
          {withAdvizerName && (
            <>
              <Space h="xs" />
              <Group justify="flex-start" w="100%">
                <Text
                  titleStyle="h5"
                  fz={14}
                  c="text.6"
                  ta="left"
                  fw={500}
                  lh={1.2}
                  lineClamp={1}
                >
                  <span
                    style={{ fontWeight: 600, color: theme.colors?.primary[6] }}
                  >
                    {advizer.firstName}
                  </span>
                  , {advizer.jobTitle} @ {advizer.company}
                </Text>
              </Group>
            </>
          )}
        </Stack>
      </Group>
    </Stack>
  );
};
