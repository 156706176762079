import { IVideo } from "@/types/ApiTypes";
import { IAdvizerModel } from "./Advizer";

export class AdvizerVideo {
  declare id: IVideo["id"];
  declare titleRaw: IVideo["title"];
  declare url: IVideo["url"];
  declare question: IVideo["question"];
  declare createdAt: IVideo["createdAt"];
  declare updatedAt: IVideo["updatedAt"];
  declare descriptors: any[];
  declare duration: IVideo["duration"];
  advizer: IAdvizerModel;
  questionIdRaw: IVideo["questionId"];

  constructor(apiData: any) {
    const { title, questionId, advizerId, ...rest } = apiData;
    Object.assign(this, rest);

    this.titleRaw = title;
    this.questionIdRaw = questionId;
    this.advizer = apiData.advizer;
    this.descriptors = apiData.descriptors;
    this.duration = apiData.duration;
  }

  get advizerId() {
    return this.advizer?.id;
  }

  get title() {
    return `${this.question?.title || this.titleRaw || ""}`;
  }

  get questionId() {
    return this.question?.id || this.questionIdRaw;
  }

  get questionNumber() {
    return this.question?.number;
  }

  get videoVimeoId() {
    return (this.url.split("/").pop() || "").split("?")[0];
  }

  get visibleDescriptors() {
    return this.descriptors?.slice(0, 3);
  }

  get durationDisplay() {
    // return in minutes and seconds
    if (!this.duration) return "";
    const minutes = Math.floor(this.duration / 60);
    const seconds = this.duration % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }
}

export interface IAdvizerVideo extends AdvizerVideo {}
