import { Button } from "@/components/ui/Button";
import { IconAdjustments, IconPencil, IconX } from "@tabler/icons-react";
import {
  useActiveUser,
  useDrawer,
  useIsAdvizersFiltered,
  useUIFlags,
} from "@/store/selectors";
import { useStore } from "@/store";
import { useIsLaptopOrBigger } from "@/styles/useStyles";
import { drawerId as filtersDrawerId } from "@/components/drawers/AdvizerSearchDrawer";
import { useIsAdvizerProfilePage } from "@/hooks";
import { RealtimeDatabase } from "@/services/RealtimeDatabase";

export const AdvizerFilterButton = () => {
  const isAdvizersFiltered = useIsAdvizersFiltered();
  const { openDrawer } = useDrawer(filtersDrawerId);
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isAdvizerProfilePage = useIsAdvizerProfilePage();
  const isInlineAdvizerSearchOpen = useStore(
    (state) => state.advizerSearch.isInlineAdvizerSearchOpen,
  );
  const setIsInlineAdvizerSearchOpen = useStore(
    (state) => state.advizerSearch.setIsInlineAdvizerSearchOpen,
  );
  const isInlineAdvizerSearch = !isAdvizerProfilePage && isLaptopOrBigger;
  const activeUser = useActiveUser();
  const { highlightAdvizerFilterButton } = useUIFlags();

  return (
    <Button
      className={highlightAdvizerFilterButton ? "pulseBorder" : ""}
      variant={
        isAdvizersFiltered
          ? "light"
          : isInlineAdvizerSearchOpen
            ? "light"
            : "light"
      }
      color="primary.2"
      leftSection={
        isInlineAdvizerSearch && isInlineAdvizerSearchOpen ? (
          <IconX size={18} />
        ) : isAdvizersFiltered ? (
          <IconPencil size={18} />
        ) : (
          <IconAdjustments size={18} />
        )
      }
      radius="sm"
      size={isLaptopOrBigger ? "md" : "sm"}
      px="xs"
      onClick={() => {
        if (isInlineAdvizerSearch) {
          setIsInlineAdvizerSearchOpen(!isInlineAdvizerSearchOpen);
        } else {
          openDrawer();
        }

        if (activeUser && !activeUser.behavior?.didClickAdvizerFilter) {
          localStorage.setItem(
            "advize_didClickAdvizerFilter",
            "true",
          );
          RealtimeDatabase.updateCurrentUserBehaviorData({
            didClickAdvizerFilter: true,
          });
        }
      }}
    >
      {isInlineAdvizerSearch
        ? isInlineAdvizerSearchOpen
          ? "Hide"
          : "Explore"
        : isAdvizersFiltered
          ? "Adjust"
          : "Explore"}
    </Button>
  );
};
