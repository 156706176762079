import {
  Title as MantineTitle,
  TitleProps as MantineTitleProps,
} from "@mantine/core";
import { forwardRef } from "react";

export type IHeaderSize = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export interface TitleProps extends MantineTitleProps {
  size?: IHeaderSize;
  fancyFont?: boolean;
}

const orderToSize: Record<number, IHeaderSize> = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
  6: "h6",
};

export const headerSizeToFontSize: Record<IHeaderSize, string> = {
  h1: "2.125rem",
  h2: "1.625rem",
  h3: "1.375rem",
  h4: "1.125rem",
  h5: "1rem",
  h6: "0.875rem",
};

export const Title = forwardRef<HTMLHeadingElement, TitleProps>(
  ({ children, size, fancyFont, ...props }, ref) => {
    const headerSize = size ? size : orderToSize[props.order || 1];
    const fontSize = headerSizeToFontSize[headerSize];
    return (
      <MantineTitle
        ref={ref}
        fz={fontSize}
        {...props}
        style={{
          fontFamily: fancyFont ? "League Spartan, sans-serif" : undefined,
          ...props.style,
        }}
      >
        {children}
      </MantineTitle>
    );
  },
);

Title.displayName = "Title";
