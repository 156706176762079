import {
  alpha,
  Box,
  AspectRatio,
  Group,
  Stack,
  Skeleton,
  Flex,
  Space,
  lighten,
  Loader,
} from "@mantine/core";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player/lazy";
import { useStyles } from "@/styles/useStyles";
import {
  IconArrowLeft,
  IconArrowRight,
  IconClock,
  IconPlayerPlayFilled,
} from "@tabler/icons-react";
import {
  useDescriptorsForVideo,
  useIsLoggedIn,
  useIsRestrictedApp,
  useIsVideoPlaying,
} from "@/store/selectors";
import { useStore } from "@/store";
import { Navigation } from "@/services/Navigation";
import { Analytics } from "@/services/Analytics";
import travoltaGif from "@/assets/memes/travolta-confused-meme.gif";
import { Image } from "@/components/ui/Image";
import { ActionIcon } from "@/components/ui/ActionIcon";
import { Text } from "@/components/ui/Text";
import { IQuote } from "@/types/ApiTypes";

export const AdvizerVideo = (props: {
  videoId: string;
  title: string | ReactNode;
  url: string;
  questionNumber?: number;
  isInBackground?: boolean;
  withControls?: boolean;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  video?: any;
  quote?: IQuote;
  light?: boolean;
  showDescriptors?: boolean;
  isPlaylistVideo?: boolean;
  onPercentProgress?: (percentProgress: number) => void;
  onProgressEvery5Seconds?: () => void;
}) => {
  const {
    videoId,
    title,
    url,
    questionNumber,
    isInBackground,
    withControls,
    onClickNext,
    onClickPrevious,
    video,
    quote,
    light = true,
    showDescriptors = false,
    onPercentProgress,
    onProgressEvery5Seconds,
    isPlaylistVideo,
  } = props;
  const { theme } = useStyles();
  const [isVideoLoading, setIsVideoLoading] = useState(!light);
  const isVideoPlaying = useIsVideoPlaying(videoId);
  const [, setIsVideoReady] = useState(light);
  const reactPlayerRef = useRef<ReactPlayer>(null);
  const descriptors = useDescriptorsForVideo(videoId);
  const setActiveVideoId = useStore((state) => state.videos.setActiveVideoId);
  const [isPlayHovered, setIsPlayHovered] = useState(false);
  const isLoggedIn = useIsLoggedIn();
  const [durationInSeconds, setDurationInSeconds] = useState(0);
  const [secondsWatched, setSecondsWatched] = useState(0);
  const [userSecondsWatched, setUserSecondsWatched] = useState(0);
  const [previousPlayedSeconds, setPreviousPlayedSeconds] = useState(0);
  const lastReportedPercent = useRef(0);
  const lastReportedSecondsWatched = useRef(0);
  const [hasSeeked, setHasSeeked] = useState(false);
  const isRestrictedApp = useIsRestrictedApp();
  const incrementNumVideosPlayed = useStore(
    (state) => state.user.incrementNumVideosPlayed,
  );
  const videoScreenRef = useRef<HTMLDivElement>(null);
  const [videoError, setVideoError] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1.25);

  const { videoTitleFontSize, videoTitleLineClamp } = useMemo(() => {
    const titleLength = String(title).length;

    if (titleLength > 50) {
      return {
        videoTitleFontSize: 16,
        lineClamp: 2,
      };
    }

    return {
      videoTitleFontSize: 20,
      videoTitleLineClamp: 1,
    };
  }, [title]);

  const resetVideo = useCallback(() => {
    setPreviousPlayedSeconds(0);
    setHasSeeked(false);
    setSecondsWatched(0);
    setUserSecondsWatched(0);
    setIsPlayHovered(false);
  }, []);

  useEffect(() => {
    if (isInBackground && isVideoPlaying) {
      setActiveVideoId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInBackground]);

  useEffect(() => {
    if (!light) {
      setIsVideoLoading(true);
      setIsVideoReady(false);
    }
  }, [videoId, light]);

  useEffect(() => {
    if (url && !isInBackground) {
      const embedUrl = `https://noembed.com/embed?url=${url}`;

      fetch(embedUrl)
        .then((res) => res.json())
        .then((data) => {
          if (data?.error) {
            setVideoError(true);
            Analytics.videoError({ error: data.error, videoId, url });
          } else {
            setVideoError(false);
          }
        });
    }
  }, [url, isInBackground, videoId]);

  const handlePlayVideo = useCallback(() => {
    setActiveVideoId(videoId);

    if (!isLoggedIn) {
      if (isRestrictedApp) {
        Navigation.navToLogin();
        Analytics.click({
          action: "Play Video > Not logged in",
        });
      }
    }
  }, [isLoggedIn, isRestrictedApp, setActiveVideoId, videoId]);

  const handleVideoReady = useCallback(() => {
    setIsVideoReady(true);
    setIsVideoLoading(false);
  }, []);

  const handleVideoProgress = useCallback(
    (data: {
      played: number;
      playedSeconds: number;
      loaded: number;
      loadedSeconds: number;
    }) => {
      if (!isVideoPlaying) {
        console.log("progress but not playing");
        return;
      }

      const roundedPercent = Math.floor((data.played * 100) / 5) * 5;
      let localHasSeeked = hasSeeked;

      const currentPlayedSeconds = Math.floor(data.playedSeconds);
      // const adjustedUserSeconds = Math.floor(userSecondsWatched * playbackRate);
      // console.log({
      //   adjustedUserSeconds,
      //   remainder: adjustedUserSeconds % 5,
      // });
      const newUserSecondsWatched =
        currentPlayedSeconds > 0 ? userSecondsWatched : 0;

      console.log({
        currentPlayedSeconds,
        previousPlayedSeconds,
        diff: Math.abs(currentPlayedSeconds - previousPlayedSeconds),
      });
      if (Math.abs(currentPlayedSeconds - previousPlayedSeconds) > 2.5) {
        setHasSeeked(true);
        localHasSeeked = true;
        Analytics.seekVideo({
          video,
          videoSeekFromSeconds: previousPlayedSeconds,
          videoSeekToSeconds: data.playedSeconds,
          videoDurationSeconds: durationInSeconds,
          videoHasSeeked: hasSeeked,
          isPlaylistVideo,
        });
        setPreviousPlayedSeconds(data.playedSeconds);
        return;
      }

      setPreviousPlayedSeconds(data.playedSeconds);

      if (roundedPercent > lastReportedPercent.current) {
        lastReportedPercent.current = roundedPercent;
        setSecondsWatched(Math.floor(data.playedSeconds));
        onPercentProgress?.(roundedPercent);
        Analytics.videoProgress({
          video,
          videoWatchedSeconds: Math.floor(data.playedSeconds),
          videoWatchedPercent: roundedPercent,
          videoDurationSeconds: durationInSeconds,
          videoHasSeeked: localHasSeeked,
          isPlaylistVideo,
        });
      }

      if (
        currentPlayedSeconds >= 5 &&
        currentPlayedSeconds - lastReportedSecondsWatched.current >= 5
      ) {
        onProgressEvery5Seconds?.();
        lastReportedSecondsWatched.current = currentPlayedSeconds;
        Analytics.videoProgressEvery5Seconds({
          video,
          userSecondsWatched: newUserSecondsWatched,
          videoDurationSeconds: durationInSeconds,
          videoWatchedSeconds: Math.floor(data.playedSeconds),
          videoWatchedPercent: roundedPercent,
          videoHasSeeked: localHasSeeked,
          isPlaylistVideo,
        });
      }

      setUserSecondsWatched((s) => s + 1);
    },
    [
      durationInSeconds,
      hasSeeked,
      isPlaylistVideo,
      isVideoPlaying,
      onPercentProgress,
      onProgressEvery5Seconds,
      previousPlayedSeconds,
      userSecondsWatched,
      video,
    ],
  );

  const VideoTime = useMemo(() => {
    // console.log("video.durationDisplay", video.durationDisplay);
    if (!video.durationDisplay) return <Space h={8} />;
    return (
      <Group gap={4} justify="flex-end" p="xs">
        <Flex
          bg="primary.9"
          display="flex"
          align="center"
          py={6}
          px={8}
          style={{ borderRadius: 4 }}
        >
          <IconClock size={16} color={theme.colors.text[4]} />
          <Text c="white" size="sm" ml={6} fw={900}>
            {video.durationDisplay}
          </Text>
        </Flex>
      </Group>
    );
  }, [theme.colors.text, video.durationDisplay]);

  const FloatingDescriptors = useMemo(() => {
    if ((isPlayHovered || isVideoPlaying || !light) && !showDescriptors) {
      return null;
    }

    return (
      <Stack
        w="100%"
        h="100%"
        pos="absolute"
        top={0}
        bottom={0}
        right={0}
        gap={0}
        style={{ zIndex: 0 }}
        // p="sm"
        // pr={0}
        justify="flex-end"
        align="space-between"
      >
        {descriptors?.map((descriptor: any) => {
          return (
            <Text
              key={descriptor.id}
              ta="right"
              m={0}
              p="xs"
              pr="md"
              size="xl"
              lh={1.2}
              fw={700}
              c="text.1"
              style={{
                zIndex: 0,
                textShadow: "0 0 10px rgba(0, 0, 0, 1)",
              }}
            >
              {descriptor.text}
            </Text>
          );
        })}
        {light ? VideoTime : <Space h={44} />}
      </Stack>
    );
  }, [
    isPlayHovered,
    isVideoPlaying,
    light,
    showDescriptors,
    descriptors,
    VideoTime,
  ]);

  const VideoLoading = useMemo(() => {
    if (light) return null;
    return (
      <div
        ref={videoScreenRef}
        style={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Skeleton h="100%" w="100%" animate />
        <Stack>
          <Loader
            size="lg"
            color="primary.5"
            style={{
              position: "absolute",
              top: "calc(50% - 20px)",
              left: "calc(50% - 20px)",
            }}
          />
        </Stack>
        {/* <DVDStyleLoaderOverlay containerRef={videoScreenRef} /> */}
      </div>
    );
  }, [light]);

  const renderCoreContent = useCallback(() => {
    if (!videoId) {
      return null;
    }

    if (videoError) {
      return (
        <Image
          alt="Travolta confused meme"
          src={travoltaGif}
          fit="scale-down"
          noRatio
          quote={quote}
          memeText={
            <>
              <Text
                size="xl"
                titleStyle="h2"
                c="white"
                style={{ WebkitTextStroke: "1px black" }}
              >
                So... where's the video?
              </Text>
              <Text
                titleStyle="h3"
                ta="center"
                c="white"
                style={{ WebkitTextStroke: "1px black" }}
                maw={400}
              >
                Our bad! Seems something went wrong and we'll look into it ASAP.
              </Text>
            </>
          }
        />
      );
    }

    return (
      <>
        <ReactPlayer
          ref={reactPlayerRef}
          config={{
            vimeo: {
              playerOptions: {
                cc: true,
              },
            },
          }}
          url={url}
          width="100%"
          height="100%"
          playing={isVideoPlaying}
          light={light}
          controls
          playsinline={true}
          volume={1}
          // muted={!!isMobileDevice}
          playbackRate={playbackRate}
          fallback={<Box h="100%" w="100%" bg="background.4" />}
          style={{
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            opacity: isVideoLoading ? 0 : 1,
          }}
          playIcon={
            <Group
              className={isPlayHovered ? "pulseBorder" : ""}
              bg={
                isPlayHovered
                  ? alpha(theme.colors.primary[9], 0.9)
                  : alpha(theme.colors.primary[9], 0.1)
              }
              p={8}
              style={{ borderRadius: "50%", cursor: "pointer", zIndex: 1 }}
            >
              <IconPlayerPlayFilled
                color={
                  isPlayHovered ? theme.colors.text[0] : theme.colors.text[2]
                }
                size={40}
              />
            </Group>
          }
          onReady={handleVideoReady}
          onStart={() => {
            resetVideo();
            incrementNumVideosPlayed();
            Analytics.startVideo({
              video,
              videoDurationSeconds: durationInSeconds,
            });

            // TODO track video starts to determine most popular videos
          }}
          onProgress={handleVideoProgress}
          onDuration={(d) => {
            setDurationInSeconds(d);
          }}
          onPlay={() => {
            setActiveVideoId(videoId);
            handlePlayVideo();
          }}
          onError={(error) => {
            console.log("error", error);
          }}
          onPause={() => {
            Analytics.pauseVideo({
              video,
              videoWatchedSeconds: secondsWatched,
              videoWatchedPercent: lastReportedPercent.current,
              videoDurationSeconds: durationInSeconds,
              userSecondsWatched,
            });
          }}
          onClickPreview={() => {
            handlePlayVideo();
          }}
          onPlaybackRateChange={(rate: number) => {
            setPlaybackRate(rate);
          }}
        />
        {isVideoLoading && !light ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "100%",
            }}
          >
            {VideoLoading}
          </div>
        ) : null}
        {FloatingDescriptors}
        {/* {false && quote && !isVideoPlaying && !isPlayHovered ? (
          <Stack
            pos="absolute"
            top={0}
            bottom={0}
            right={0}
            align="flex-end"
            justify="flex-end"
          >
            <Stack
              align="flex-end"
              justify="flex-end"
              p={0}
              h="100%"
              w="100%"
              gap={0}
              ta="left"
              opacity={isPlayHovered ? 0.25 : 1}
              onClick={() => {
                handlePlayVideo(); 
              }}
              style={{
                overflow: "auto",
                zIndex: 0,
              }}
            >
              <Text
                ta="left"
                lh={1.3}
                fz={
                  (quote?.snippet?.length || quote?.text?.length || 0) > 125
                    ? isMobile
                      ? 16
                      : 20
                    : isMobile
                      ? 20
                      : 22
                }
                c="white"
                bg={alpha(theme.colors.secondary[9], 0.95)}
                px="md"
                py="md"
                style={{
                  color: "white",
                  borderRadius: 2,
                  lineHeight: 1.3,
                  fontWeight: 600,
                }}
              >
                {`"${quote.snippet || quote.text}"`}
              </Text>
            </Stack>
          </Stack>
        ) : null} */}
      </>
    );
  }, [
    FloatingDescriptors,
    VideoLoading,
    durationInSeconds,
    handlePlayVideo,
    handleVideoProgress,
    handleVideoReady,
    incrementNumVideosPlayed,
    isPlayHovered,
    isVideoLoading,
    isVideoPlaying,
    light,
    playbackRate,
    quote,
    resetVideo,
    secondsWatched,
    setActiveVideoId,
    theme.colors.primary,
    theme.colors.text,
    url,
    userSecondsWatched,
    video,
    videoError,
    videoId,
  ]);

  return (
    <Stack
      gap={0}
      h="100%"
      w="100%"
      justify="flex-start"
      style={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}
      display={isInBackground ? "none" : "flex"}
    >
      <Group
        bg="primary.9"
        px="xs"
        py="xs"
        w="100%"
        justify="space-between"
        gap={8}
      >
        {onClickPrevious ? (
          <NextPrevButton onClick={onClickPrevious} hide={!withControls} />
        ) : null}
        <Text
          titleStyle="h1"
          fz={videoTitleFontSize}
          flex={1}
          c={theme.colors.text[0]}
          ta="left"
          px={withControls ? 4 : 8}
          lineClamp={videoTitleLineClamp}
        >
          {questionNumber && (
            <span style={{ color: theme.colors.primary[3], paddingRight: 8 }}>
              Q{questionNumber}
            </span>
          )}
          {title}
        </Text>
        {onClickNext ? (
          <NextPrevButton onClick={onClickNext} isRight hide={!withControls} />
        ) : null}
      </Group>
      <AspectRatio
        component="div"
        ratio={16 / 9}
        w="100%"
        pos="relative"
        style={{
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
          overflow: "hidden",
          cursor: "pointer",
        }}
        bg="background.2"
        onMouseEnter={() => {
          setIsPlayHovered(true);
        }}
        onMouseLeave={() => {
          setIsPlayHovered(false);
        }}
      >
        {renderCoreContent()}
      </AspectRatio>
    </Stack>
  );
};

function NextPrevButton(props: {
  onClick: () => void;
  isRight?: boolean;
  hide?: boolean;
}) {
  const { theme } = useStyles();
  return (
    <Stack h={42}>
      <ActionIcon
        onClick={props.hide ? undefined : props.onClick}
        size={props.hide ? 10 : "xl"}
        radius="xl"
        color={lighten(theme.colors.primary[9], 0.075)}
        opacity={props.hide ? 0 : 1}
        ariaLabel={props.isRight ? "Go to previous video" : "Go to next video"}
      >
        {props.isRight ? <IconArrowRight /> : <IconArrowLeft />}
      </ActionIcon>
    </Stack>
  );
}
