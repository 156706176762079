import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App.tsx";
import mixpanel from "mixpanel-browser";
import Hotjar from "@hotjar/browser";
import { config } from "./config.ts";

if (config.isProductionClient) {
  console.log("Initializing Sentry");
  Sentry.init({
    dsn: "https://d04eeca603e8ddae87358eed98fd37fa@o4508164322099200.ingest.us.sentry.io/4508164324524032",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/.*advize.*\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const siteId = 5186496;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  mixpanel.init("a8155d1e7f6a34c6d534724e421fd884", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });
} else {
  console.log("Not initializing Sentry or Hotjar");
  Sentry.init({});
}

createRoot(document.getElementById("root")!).render(
  // <StrictMode>
  <App />,
  // </StrictMode>
);
