import { Stack, Text } from "@mantine/core";
import { Button } from "@/components/ui/Button";
import { useDialog } from "@/store/selectors";
import { Link } from "@/components/ui/Link";
import { RealtimeDatabase } from "@/services/RealtimeDatabase";
import { Analytics } from "@/services/Analytics";
import { config } from "@/config";

export function CookieDialog() {
  const { closeActiveDialog } = useDialog();

  function handleAccept() {
    closeActiveDialog();
    RealtimeDatabase.updateCurrentUser({
      acceptedCookies: true,
    });
    Analytics.acceptCookies();
  }

  function handleReject() {
    closeActiveDialog();
    RealtimeDatabase.updateCurrentUser({
      rejectedCookies: true,
      acceptedCookies: false,
    });
    Analytics.rejectCookies();
  }

  return (
    <Stack p={0}>
      <Stack py="sm" gap={0}>
        <Text>
          Cookies help us give you the best experience possible and improve
          Advize. Learn more in our{" "}
          <Link to={config.privacyUrl} target="_blank">
            Privacy Policy
          </Link>
          .
        </Text>
      </Stack>

      <Button fullWidth onClick={handleAccept} size="md" variant="filled">
        Accept All
      </Button>
      <Button
        fullWidth
        onClick={handleReject}
        size="sm"
        variant="outline"
        opacity={0.7}
      >
        Reject Non-Essental Cookies
      </Button>
    </Stack>
  );
}
