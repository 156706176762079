import {
  useActivePlaylistOutcome,
  useGoToNextStepInPlaylist,
} from "@/store/selectors/playlistSelectors";
import { USER_PROFILE_DATA_COLLECTION_STEP_TYPES } from "@/configs/playlists/playlistStepTypeConfigs";
import { useEffect, useMemo } from "react";
import { Navigation } from "@/services/Navigation";

export const PlaylistProfileBuilder = () => {
  const activePlaylistOutcome = useActivePlaylistOutcome();
  const goToNextStepInPlaylist = useGoToNextStepInPlaylist();
  console.log("activePlaylistOutcome", activePlaylistOutcome);

  const nextRequiredProfileDataStep = useMemo(() => {
    return (activePlaylistOutcome.requiredProfileDataSteps || []).map((s) => {
      return {
        ...USER_PROFILE_DATA_COLLECTION_STEP_TYPES[s],
        stepType: s,
      };
    })[0];
  }, [activePlaylistOutcome]);

  useEffect(() => {
    if (activePlaylistOutcome && nextRequiredProfileDataStep) {
      Navigation.navToPlaylistStep(nextRequiredProfileDataStep.stepType);
    } else {
      goToNextStepInPlaylist();
    }
  }, [
    activePlaylistOutcome,
    goToNextStepInPlaylist,
    nextRequiredProfileDataStep,
  ]);

  return null;
};
