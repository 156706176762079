import { useCallback, useEffect } from "react";
import { shuffleArray } from "@/lib/helpers";
import { AdvizeApi } from "@/api/api";
import { useIsApiDown, useSetApiData } from "@/store/selectors";
import { Advizer } from "@/models/Advizer";
import { IAdvizeApiDataKeys } from "@/types/StoreTypes";
import { IGenericApiQueryParams } from "@/api/api";

export const Api = () => {
  const setApiData = useSetApiData();
  const isApiDown = useIsApiDown();

  const init = useCallback(async () => {
    const fetchAndSetData = async (params: {
      apiCall: (config?: IGenericApiQueryParams) => Promise<any>;
      key: IAdvizeApiDataKeys;
      transform?: (data: any) => any;
      shuffle?: boolean;
    }) => {
      const { apiCall, key, transform, shuffle } = params;
      let items: any[] = [];
      try {
        const response = await apiCall();
        items =
          response?.data?.items?.items ||
          response?.data?.items ||
          response?.data ||
          [];

        if (!items.length) {
          throw new Error("No items found");
        }
      } catch (e) {
        console.error(e);
        // if (!items.length) {
        //   const response2 = await apiCall({ noCache: true });
        //   items =
        //     response2?.data?.items?.items ||
        //     response2?.data?.items ||
        //     response2?.data ||
        //     [];
        // }
      }

      if (shuffle) {
        items = shuffleArray(items);
      }

      setApiData({ key, data: transform ? items.map(transform) : items });
    };

    await Promise.all([
      fetchAndSetData({
        apiCall: (config) => AdvizeApi.queryPartners(config),
        key: "partners",
      }),
      fetchAndSetData({
        apiCall: (config) =>
          AdvizeApi.queryAdvizersWithEverything({
            take: 1000,
            skip: 0,
            ...config,
          }),
        key: "advizers",
        transform: (a: any) => new Advizer(a),
        shuffle: true,
      }),
    ]);
    // fetchAndSetData(
    await Promise.all([
      // fetchAndSetData(
      //   (config) =>
      //     AdvizeApi.queryBasicAdvizers({
      //       take: 1000,
      //       skip: 0,
      //       ...config,
      //     }),
      //   "advizers",
      //   (a: any) => new Advizer(a),
      //   true,
      // ),
      // fetchAndSetData({
      //   apiCall: (config) =>
      //     AdvizeApi.queryAdvizersWithEverything({
      //       take: 1000,
      //       skip: 0,
      //       ...config,
      //     }),
      //   key: "advizers",
      //   transform: (a: any) => new Advizer(a),
      //   shuffle: true,
      // }),
      fetchAndSetData({
        apiCall: (config) => AdvizeApi.querySubjects(config),
        key: "subjects",
      }),
      fetchAndSetData({
        apiCall: (config) => AdvizeApi.queryJobFunctions(config),
        key: "jobFunctions",
      }),
      fetchAndSetData({
        apiCall: (config) => AdvizeApi.queryIndustries(config),
        key: "industries",
      }),
      fetchAndSetData({
        apiCall: (config) => AdvizeApi.queryTraits(config),
        key: "traits",
      }),
      fetchAndSetData({
        apiCall: (config) => AdvizeApi.queryQuestions(config),
        key: "questions",
      }),
      // fetchAndSetData({
      //   apiCall: (config) => AdvizeApi.queryPartners(config),
      //   key: "partners",
      // }),
      fetchAndSetData({
        apiCall: (config) => AdvizeApi.queryQuotes(config),
        key: "quotes",
      }),
      fetchAndSetData({
        apiCall: (config) => AdvizeApi.queryDescriptors(config),
        key: "descriptors",
      }),
    ]);
  }, [setApiData]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isApiDown) {
      console.error("API is down");
    }
  }, [isApiDown]);

  return null;
};
