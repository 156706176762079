import { PlaylistRadioButtons } from "./PlaylistRadioButtons";
import { PLAYLIST_OUTCOMES } from "@/configs/playlists/playlistOutcomeConfigs";
import { useMemo } from "react";
import { useStore } from "@/store";
import { useGoToNextStepInPlaylist } from "@/store/selectors/playlistSelectors";

export const PlaylistOutcomeOptions = () => {
  const setActivePlaylistOutcomeKey = useStore(
    (state) => state.playlists.setActivePlaylistOutcomeKey,
  );
  const activePlaylistOutcomeKey = useStore(
    (state) => state.playlists.activePlaylistOutcomeKey,
  );
  const goToNextStepInPlaylist = useGoToNextStepInPlaylist();

  const playlistOutcomeOptions = useMemo(
    // TODO randomize order
    () =>
      Object.entries(PLAYLIST_OUTCOMES)
        .sort(() => Math.random() - 0.5)
        .map(([key, value]) => ({
          label: value.title,
          value: key,
          Icon: value.Icon,
          color: value.color,
          onClick: () => {
            console.log("GO TO NEXT STEP IN PLAYLIST");
            setActivePlaylistOutcomeKey(key);
            // RealtimeDatabase.updateCurrentUserUiStateData({
            //   latestPlaylistOutcomeKey: key,
            // });
            goToNextStepInPlaylist();
          },
        })),
    [goToNextStepInPlaylist, setActivePlaylistOutcomeKey],
  );

  return (
    <PlaylistRadioButtons
      options={playlistOutcomeOptions}
      selectedValue={activePlaylistOutcomeKey}
    />
  );
};
