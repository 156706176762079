import {
  Alert,
  Group,
  PasswordInput,
  Space,
  Stack,
  TextInput,
} from "@mantine/core";
import validator from "validator";
import {
  IconBrandTelegram,
  IconInfoCircle,
  IconLink,
} from "@tabler/icons-react";
import { useIsDesktop, useStyles } from "@/styles/useStyles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Firebase } from "@/firebase/firebase";
import { useNavigate, useParams } from "react-router-dom";
import {
  useIsLoggedIn,
  useIsRestrictedApp,
  usePartnerBySlug,
  usePartners,
} from "@/store/selectors";
import { Link } from "@/components/ui/Link";
import { Button } from "@/components/ui/Button";
import { Image } from "@/components/ui/Image";
import { checkIfMatchesEmailFormat } from "@/lib/helpers";
import { Analytics } from "@/services/Analytics";
import { Title } from "@/components/ui/Title";
import { Text } from "@/components/ui/Text";
import { GoogleAuthButton } from "@/components/auth/GoogleAuthButton";
import { PartnerSelector } from "@/components/PartnerSelector";
import { TermsAndConditions } from "@/components/TermsAndConditions";
import { Navigation } from "@/services/Navigation";
import { useActivePlaylistId } from "@/store/selectors/playlistSelectors";
import { Playlist } from "@/components/playlists/Playlist";

export const Login = (props: {
  isPartnerSelection?: boolean;
  isGeneralLogin?: boolean;
}) => {
  const { isPartnerSelection, isGeneralLogin } = props;
  const isDesktop = useIsDesktop();
  const { theme } = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordLogin, setIsPasswordLogin] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { partnerSlug } = useParams();
  const partner = usePartnerBySlug(partnerSlug);
  const isRestrictedApp = useIsRestrictedApp();
  const partners = usePartners();
  const activePlaylistId = useActivePlaylistId();

  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [navigate, isLoggedIn]);

  useEffect(() => {
    if (partner?.hideFromLogin) {
      navigate("/login/partners");
    }
  }, [partner, navigate]);

  useEffect(() => {
    if (partnerSlug === "arizona-eller") {
      const arizonaEllerPartner = partners.find((partner) =>
        partner.slug?.includes("eller"),
      );
      if (arizonaEllerPartner) {
        Navigation.navigate(`/login/${arizonaEllerPartner?.slug}`);
      }
    }
  }, [partnerSlug, partners]);

  const handleSubmit = useCallback(async () => {
    console.log("handleSubmit", { partner, email, isPasswordLogin });
    function handleError(errorMessage: string) {
      console.log("handleError", errorMessage);
      setEmailError(errorMessage);
      Analytics.invalidEmail({
        inputValue: email,
        error: errorMessage,
      });
    }

    if (!email) {
      handleError("Please enter your email");
      return;
    }

    if (
      partner?.emailFormat &&
      !checkIfMatchesEmailFormat({ email, emailFormat: partner.emailFormat })
    ) {
      handleError(`Please enter your email for ${partner.title}`);
      return;
    }

    if (!validator.isEmail(email)) {
      handleError("Please enter a valid email");
      return;
    }

    if (isPasswordLogin && !password) {
      setPasswordError("Please enter your password");
      return;
    }

    if (isPasswordLogin && password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }

    setIsLoggingIn(true);

    if (isPasswordLogin) {
      const response = await Firebase.loginWithEmailAndPassword({
        email,
        password,
        partnerId: partner?.id,
      });
      if (response?.error) {
        setIsLoggingIn(false);
        setPasswordError(response.error);
        return;
      }
    } else {
      console.log("sendSignInLink", { email, partner });
      await Firebase.sendSignInLink({ email, partnerId: partner?.id });
      setEmailSent(true);
      setIsLoggingIn(false);
    }
  }, [email, isPasswordLogin, partner, password]);

  const renderWatchAdvizersHero = useCallback(() => {
    if (!isGeneralLogin) return null;
    return (
      <Stack w="100%" gap={0}>
        <Title order={1} c="text.9">
          Sign in or create an account
        </Title>
      </Stack>
    );
  }, [isGeneralLogin]);

  const renderHeaderText = useCallback(() => {
    if (isGeneralLogin) {
      return (
        <Stack w="100%" gap="xs">
          {!activePlaylistId ? <Space h="10vh" /> : null}
          {isPasswordLogin ? (
            renderWatchAdvizersHero()
          ) : (
            <Title order={1} fz={isDesktop ? 40 : 32} c="text.8">
              Sign in with a magic link
            </Title>
          )}
          <Title order={2} size="h4" fw={500} c="text.7">
            We'll create an account if you don't have one yet.
          </Title>
          <Space h="xl" />
        </Stack>
      );
    }

    return (
      <>
        {renderWatchAdvizersHero()}
        <Space h="xs" />
      </>
    );
  }, [
    activePlaylistId,
    isDesktop,
    isGeneralLogin,
    isPasswordLogin,
    renderWatchAdvizersHero,
  ]);

  const renderTextInput = useCallback(() => {
    return (
      <TextInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
        ta="left"
        label={"Enter your email"}
        placeholder="khaleesi@dragonstone.edu"
        error={emailError}
        w="100%"
        size="md"
        labelProps={{
          size: "md",
          fw: 800,
          c: "text.8",
          mb: 4,
          pl: 4,
        }}
      />
    );
  }, [email, emailError, handleSubmit]);

  const renderPasswordInput = useCallback(() => {
    return (
      <PasswordInput
        w="100%"
        size="md"
        ta="left"
        value={password}
        label={
          <span>
            Enter your password{" "}
            <span style={{ opacity: 0.8, fontStyle: "" }}>(or create one)</span>
          </span>
        }
        placeholder="********"
        error={passwordError}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
          }
        }}
        labelProps={{
          size: "md",
          fw: 800,
          c: "text.8",
          mb: 4,
          pl: 4,
        }}
      />
    );
  }, [password, handleSubmit, passwordError]);

  const renderLoginOptions = useCallback(
    (props?: { noGoogle?: boolean }) => {
      const { noGoogle } = props || {};
      if (isPasswordLogin) {
        return (
          <>
            {" "}
            <Space h="sm" />
            {renderTextInput()}
            <Space h="lg" />
            {renderPasswordInput()}
            <Space h="md" />
            <Button
              variant="filled"
              radius="xl"
              size="lg"
              w="100%"
              onClick={handleSubmit}
              loading={isLoggingIn}
            >
              Login{" "}
            </Button>{" "}
            {noGoogle || !isPasswordLogin ? null : (
              <>
                <Space h="lg" />
                <Text size="md" c="text.4">
                  or
                </Text>
                <Space h="lg" />
                <GoogleAuthButton />
              </>
            )}
            <Space h="xl" />
            <Group gap={0}>
              <Text size="sm" c="text.6">
                Want to sign in with a magic link?
              </Text>
              <Button
                variant="transparent"
                size="sm"
                c="primary.5"
                px={4}
                style={{ textDecoration: "underline", fontWeight: 500 }}
                onClick={(event) => {
                  setIsPasswordLogin(false);
                  Analytics.click({
                    action: "Login with magic link",
                    event,
                  });
                }}
              >
                Click here
              </Button>
            </Group>
          </>
        );
      }

      return (
        <>
          <Space h="sm" />

          <Stack w="100%">
            {renderTextInput()}
            <Button
              variant="filled"
              radius="xl"
              size="lg"
              w="100%"
              leftSection={<IconLink />}
              onClick={handleSubmit}
            >
              Get a magic link in your inbox
            </Button>{" "}
          </Stack>
          {noGoogle || !isPasswordLogin ? null : (
            <>
              <Space h="lg" />
              <Text size="md" c="text.4">
                or
              </Text>
              <Space h="lg" />
              <GoogleAuthButton />
            </>
          )}
          <Space h="xl" />
          <Group gap={0}>
            <Text size="md" c="text.6">
              Want to sign in with a password?
            </Text>
            <Button
              variant="transparent"
              size="md"
              c="primary.5"
              px={4}
              style={{ textDecoration: "underline", fontWeight: 500 }}
              onClick={(event) => {
                setIsPasswordLogin(true);
                Analytics.click({
                  action: "Login with password",
                  event,
                });
              }}
            >
              Click here
            </Button>
          </Group>
        </>
      );
    },
    [
      handleSubmit,
      isLoggingIn,
      isPasswordLogin,
      renderPasswordInput,
      renderTextInput,
    ],
  );

  const renderChooseLoginDefault = useCallback(() => {
    return (
      <Stack w="100%" gap="md" maw={375}>
        {isRestrictedApp && (
          <>
            <Alert
              color="orange"
              icon={<IconInfoCircle />}
              title="Just a sec!"
              ta="left"
            >
              Please create a free account to keep watching videos.
            </Alert>
            <Space h="sm" />
          </>
        )}
        <Title order={1} c="text.9">
          Are you a student?
        </Title>
        <Space h="sm" />
        <Button
          fullWidth
          size="md"
          to="/login/partners"
          radius="xl"
          color="primary"
        >
          Yep, I'll log in with my school email
        </Button>
        <Button
          to="/login/general"
          fullWidth
          size="md"
          variant="outline"
          radius="xl"
          color="primary"
        >
          Naw, I'm not a student
        </Button>
        <Button
          fullWidth
          size="md"
          variant="light"
          to="/login/partners"
          radius="xl"
          color="primary"
        >
          Hmmm, I'm not sure
        </Button>
      </Stack>
    );
  }, [isRestrictedApp]);

  const renderTerms = useCallback(() => {
    if (isPartnerSelection) return null;
    return <TermsAndConditions />;
  }, [isPartnerSelection]);

  const renderNoPartnerLoginLink = useCallback(() => {
    return (
      <Group gap={0} justify="center">
        <Text size="md" c="text.8">
          <Link to="/login/general">
            Don't see your school? Click here to log in.
          </Link>
        </Text>
      </Group>
    );
  }, []);

  const renderPartnerSelection = useCallback(() => {
    return (
      <Stack w="100%" gap="xs">
        {!activePlaylistId ? <Space h="10vh" /> : null}
        <Title order={1} c="text.9">
          Choose your Advize partner
        </Title>
        {renderNoPartnerLoginLink()}
        <Space h="sm" />
        <PartnerSelector />
        <Space h="sm" />
        {renderNoPartnerLoginLink()}
      </Stack>
    );
  }, [activePlaylistId, renderNoPartnerLoginLink]);

  const renderPartnerLoginHeader = useCallback(() => {
    return (
      <Stack w="100%" gap={0} align="center">
        <Image
          src={partner?.logoURL}
          alt={partner?.title}
          w={300}
          mah={300}
          ratio={16 / 9}
          fit={
            partner.title.includes("Knauss") ||
            partner.title.includes("Stanford") ||
            partner.title.includes("Eller")
              ? "contain"
              : "cover"
          }
          {...partner?.styles?.loginHeaderImageProps}
        />
        <Title order={2} size="h4" fw={500} c="primary.7">
          We'll create an account if you don't have one yet!
        </Title>
      </Stack>
    );
  }, [partner]);

  const renderPartnerLogin = useCallback(() => {
    if (!partner) return null;
    return (
      <Stack gap={0} w="100%" align="center">
        {renderPartnerLoginHeader()}
        <Space h="md" />
        {renderLoginOptions({ noGoogle: true })}
      </Stack>
    );
  }, [partner, renderLoginOptions, renderPartnerLoginHeader]);

  const renderContent = useCallback(() => {
    if (partner) {
      return renderPartnerLogin();
    }
    if (isPartnerSelection) {
      return renderPartnerSelection();
    }

    if (isGeneralLogin) {
      return renderLoginOptions();
    }

    return renderChooseLoginDefault();
  }, [
    partner,
    isPartnerSelection,
    isGeneralLogin,
    renderChooseLoginDefault,
    renderPartnerLogin,
    renderPartnerSelection,
    renderLoginOptions,
  ]);

  const CoreContent = useMemo(() => {
    return (
      <Stack
        w="100%"
        maw={isPartnerSelection ? 550 : 450}
        gap={0}
        align="center"
      >
        {renderHeaderText()}
        {renderContent()}
        <Space h="xl" />
        {renderTerms()}
      </Stack>
    );
  }, [isPartnerSelection, renderContent, renderHeaderText, renderTerms]);

  if (emailSent) {
    return (
      <Stack
        align="center"
        justify="center"
        h="100%"
        mih="100vh"
        ta="center"
        gap={0}
        p="sm"
        maw={500}
        mx="auto"
      >
        <Group w="100%" align="center" justify="center">
          <IconBrandTelegram size={64} color={theme.colors.text[6]} />
        </Group>
        <Space h="lg" />
        <Title order={1} lh={1} fz={isDesktop ? 48 : 40} c="text.8">
          Check your inbox!
        </Title>
        <Space h="xs" />
        <Text size="lg" c="text.6">
          We've sent you a magic link to {email || "your email"}. Click the link
          to sign in and start watching Advizers.
        </Text>
      </Stack>
    );
  }

  if (activePlaylistId && !partner) {
    return <Playlist>{CoreContent}</Playlist>;
  }

  return (
    <Stack
      align="center"
      justify={isPartnerSelection ? "flex-start" : "center"}
      bg={theme?.colors?.loginBackground?.[0] || theme.colors.background[0]}
      // h="100%"
      mih="100vh"
      ta="center"
      gap={0}
      p="sm"
      pt={isPartnerSelection || isGeneralLogin ? 60 : 36}
      pb={100}
      w="100%"
      mx="auto"
    >
      {CoreContent}
    </Stack>
  );

  // return (
  //   <Stack
  //     align="center"
  //     justify={isPartnerSelection ? "flex-start" : "center"}
  //     bg={theme?.colors?.loginBackground?.[0] || theme.colors.background[0]}
  //     // h="100%"
  //     mih="100vh"
  //     ta="center"
  //     gap={0}
  //     p="sm"
  //     pt={isPartnerSelection || isGeneralLogin ? 60 : 36}
  //     pb={100}
  //     w="100%"
  //     mx="auto"
  //   >
  //     <Stack
  //       w="100%"
  //       maw={isPartnerSelection ? 550 : 450}
  //       gap={0}
  //       align="center"
  //     >
  //       {renderHeaderText()}
  //       {renderContent()}
  //       <Space h="xl" />
  //       {renderTerms()}
  //     </Stack>
  //   </Stack>
  // );
};
