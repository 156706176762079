import { Divider, Space, Stack } from "@mantine/core";
import { PlaylistRadioButtons } from "./PlaylistRadioButtons";
import { IconMovie, IconSearch, IconUserCog } from "@tabler/icons-react";
import { Title } from "../ui/Title";
import { AdvizerProfile } from "@/pages/AdvizerProfilePage";
import { useRandomAdvizer } from "@/store/selectors";
import { Navigation } from "@/services/Navigation";
import { useGoToNextStepInPlaylist } from "@/store/selectors/playlistSelectors";

export const PlaylistInitialOptions = () => {
  const randomAdvizer = useRandomAdvizer();
  const goToNextStepInPlaylist = useGoToNextStepInPlaylist();

  return (
    <Stack w="100%" align="center" p={0}>
      <Stack maw={500} align="center">
        <PlaylistRadioButtons
          options={[
            {
              label:
                "Get recommended interview videos that match your career goals",
              // subLabel:
              //   "Get recommended interview videos that match your career goals",
              value: "getRecommendedPlaylists",
              Icon: IconMovie,
              color: "primary",
              onClick: () => {
                goToNextStepInPlaylist();
              },
            },
            {
              label:
                "Browse bite-sized video playlists that give you key insights about specific topics",
              // subLabel:
              //   "Browse bite-sized video playlists that give you key insights about specific topics",
              value: "browseAllPlaylists",
              Icon: IconSearch,
              color: "violet",
              onClick: () => {
                Navigation.navigate("/playlists");
              },
            },
            {
              label:
                "Explore Advizer profiles to find the most helpful interviews for you",
              // subLabel:
              //   "Explore Advizer profiles to find the most helpful interviews for you",
              value: "exploreProfessionals",
              Icon: IconUserCog,
              color: "orange",
              onClick: () => {
                Navigation.navigate("/explore");
              },
            },
          ]}
          selectedValue={null}
        />
      </Stack>

      <Space h="xl" />
      <Space h="xl" />
      <Divider w="100%" />

      <Space h="xl" />
      <Title order={2} ta="center" maw={600}>
        Every "Advizer" answers the same 12 questions about their daily work
        life, critical skills, entry level job ideas and more. Here's a random
        example!
      </Title>
      <Space h="xl" />
      <AdvizerProfile standalone advizerIdFromProps={randomAdvizer?.id} />
    </Stack>
  );
};
