import { Flex, Stack } from "@mantine/core";
import React, { useState } from "react";
import { Text } from "../ui/Text";
import { Button } from "../ui/Button";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import { IconArrowRight } from "@tabler/icons-react";
import { Analytics } from "@/services/Analytics";

const iconSize = 30;

export const PlaylistRadioButtons = (props: {
  selectedValue: string | number | null;
  options: {
    label: string | React.ReactNode;
    subLabel?: string | React.ReactNode;
    value: string;
    Icon?: any;
    color?: string;
    onClick?: () => void;
  }[];
}) => {
  return (
    <Stack gap="md">
      {props.options.map((option) => (
        <PlaylistRadioButton
          key={option.value}
          option={option}
          isSelected={option.value === props.selectedValue}
        />
      ))}
    </Stack>
  );
};

const PlaylistRadioButton = (props: {
  isSelected: boolean;
  option: {
    label: string | React.ReactNode;
    subLabel?: string | React.ReactNode;
    value: string;
    Icon?: any;
    color?: string;
    onClick?: () => void;
  };
}) => {
  const { theme } = useStyles();
  const option = props.option;
  const [isHovered, setIsHovered] = useState(false);
  const color = theme.colors[option.color || "text"][7];
  const isLaptopOrBigger = useIsLaptopOrBigger();

  return (
    <Button
      className="hoverable"
      key={option.value}
      variant="outline"
      w="100%"
      ta="left"
      unstyled
      maw="100%"
      px="lg"
      py="lg"
      size="xs"
      bg={props.isSelected || isHovered ? "slate.1" : "slate.0"}
      styles={{
        inner: {
          width: "100%",
        },
        label: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        },
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={() => {
        option.onClick?.();
        Analytics.click({
          action: `Playlist Radio Button > ${option.value}`,
          label: typeof option.label === "string" ? option.label : option.value,
        });
      }}
      style={{
        border: `1px solid ${theme.colors.border[3]}`,
        borderRadius: 4,
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.05)",
      }}
      display="flex"
      // rightSection={
      //   isHovered ? <IconArrowRight /> : option.Icon ? <option.Icon /> : null
      // }
    >
      <Flex ta="left" h="100%" display="flex" align="center" pr="sm">
        {option.Icon && <option.Icon size={iconSize * 1.3} color={color} />}
      </Flex>
      <Stack>
        <Text
          size="lg"
          fz={20}
          // c="slate.9"
          c={isHovered ? color : "slate.9"}
          lh={1.3}
          ta="left"
          flex={1}
          titleStyle
          fw={500}

          // lineClamp={3}
        >
          {option.label}
        </Text>
        {option.subLabel && (
          <Text size="sm" c="text.7">
            {option.subLabel}
          </Text>
        )}
      </Stack>
      {isLaptopOrBigger && (
        <Flex ta="right" px="xs" h="100%" display="flex" align="center">
          <IconArrowRight
            size={iconSize}
            color={!isHovered ? theme.colors.text[3] : color}
          />
        </Flex>
      )}
    </Button>
  );
};
