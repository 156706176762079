import {
  useEstimatedPlaylistDuration,
  useGoToNextStepInPlaylist,
  useIsPlaylistInProgressForUser,
  useIsRecommendedPlaylist,
  usePlaylistById,
} from "@/store/selectors/playlistSelectors";
import { Badge, Button, Group, Space, Spoiler, Stack } from "@mantine/core";
import { Text } from "../ui/Text";
import { IconArrowRight, IconClock, IconStar } from "@tabler/icons-react";
import { useStyles } from "@/styles/useStyles";
import { PlaylistAdvizerList } from "./PlaylistAdvizerList";
import { Navigation } from "@/services/Navigation";
import { StoreService } from "@/services/StoreService";
import { Analytics } from "@/services/Analytics";

export function PlaylistCard({
  playlistId,
  isPlaylistIntro,
  maxAdvizerListHeight,
  maxSpoilerHeight,
  advizerQuoteLineClamp,
  maxWidth,
  height,
  isPlaylistConclusion,
}: {
  playlistId: string;
  isPlaylistIntro?: boolean;
  maxAdvizerListHeight?: number;
  maxSpoilerHeight?: number;
  advizerQuoteLineClamp?: number;
  maxWidth?: number;
  height?: number;
  isPlaylistConclusion?: boolean;
}) {
  const playlist = usePlaylistById(playlistId);
  const estimatedDuration = useEstimatedPlaylistDuration(playlistId);
  const { theme } = useStyles();
  const goToNextStepInPlaylist = useGoToNextStepInPlaylist();
  const isPlaylistInProgressForUser =
    useIsPlaylistInProgressForUser(playlistId);
  const isRecommendedPlaylist = useIsRecommendedPlaylist(playlistId);

  if (!playlist) return null;

  return (
    <Group
      mx="auto"
      p="xl"
      pb="xl"
      bg="background.0"
      h={"100%"}
      w="100%"
      maw={maxWidth}
      mah={height}
      align="flex-start"
      style={{
        borderRadius: 8,
        // cursor: "pointer",
        border: `1px solid ${theme.colors.border[2]}`,
        boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.1)",
      }}
      // className="shadow-on-hover"
    >
      <Stack gap={0} w="100%" justify="space-between" h="100%">
        <Stack gap={0} w="100%">
          {/* <Link to={`/playlists/${playlistId}?autoNav=true`}> */}
          <Text
            titleStyle={isPlaylistIntro ? "h1" : "h3"}
            lh={1.1}
            ta={isPlaylistIntro ? "left" : "left"}
            c="text.9"
          >
            {playlist?.title}
          </Text>
          {/* </Link> */}
          <Space h="xs" />
          <Spoiler
            maxHeight={maxSpoilerHeight}
            showLabel="Show more"
            hideLabel="Hide"
            c="text.7"
            ta="left"
            styles={{
              control: {
                fontWeight: 400,
                fontFamily: "League Spartan, sans-serif",
                opacity: 0.5,
              },
            }}
          >
            {playlist?.description}
          </Spoiler>
          <Space h="xs" />
          <Group
            gap={4}
            align="center"
            justify={isPlaylistIntro ? "flex-start" : "flex-start"}
            w="100%"
          >
            <IconClock size={16} color={theme.colors.text[7]} />
            <Text size="sm" c="text.7">
              {estimatedDuration} mins
            </Text>
            {isRecommendedPlaylist && (
              <>
                <Space w={4} />
                <Badge
                  color="secondary"
                  size="md"
                  leftSection={<IconStar size={12} />}
                  style={{ textTransform: "none" }}
                >
                  Recommended
                </Badge>
              </>
            )}
          </Group>

          <Space h="lg" />

          <Stack>
            <PlaylistAdvizerList
              playlistId={playlistId}
              maxAdvizerListHeight={maxAdvizerListHeight}
              advizerQuoteLineClamp={advizerQuoteLineClamp}
            />
          </Stack>
          <Space h="xl" />
        </Stack>
        <Button
          variant={
            isPlaylistIntro || isPlaylistInProgressForUser ? "filled" : "light"
          }
          color="primary"
          size="md"
          radius="xl"
          fullWidth
          rightSection={<IconArrowRight size={18} />}
          mih={40}
          onClick={() => {
            const action = isPlaylistInProgressForUser ? "continue" : "start";
            if (isPlaylistIntro) {
              goToNextStepInPlaylist();
            } else {
              StoreService.clearActivePlaylist();
              Navigation.navToPlaylistAndAutoNavToNextStep({ playlistId });
            }

            Analytics.playlistStart({
              playlist,
              context: isPlaylistConclusion
                ? "playlist_conclusion"
                : isPlaylistIntro
                  ? "playlist_intro"
                  : "playlist_library",
              action,
            });
          }}
        >
          {isPlaylistInProgressForUser ? "Continue" : "Start"} Playlist
        </Button>
      </Stack>
    </Group>
  );
}
