export function isObjectEmpty(obj: Record<string, any>) {
  return Object.keys(obj).length === 0;
}

export function parsePersonalBrandingLinks(links: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = links.split(urlRegex);

  return (
    <>
      {parts.map((part, index) =>
        urlRegex.test(part) ? (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        ) : (
          part
        ),
      )}
    </>
  );
}

export function removeUndefinedFromObject(obj: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined),
  );
}

export function shuffleArray(array: any[]) {
  return [...array].sort(() => Math.random() - 0.5);
}

export function checkIfMatchesEmailFormat({
  email,
  emailFormat,
}: {
  email: string;
  emailFormat: string | string[];
}) {
  if (!emailFormat) return false;
  // const normalizedEmail = email.toLowerCase();
  // const normalizedFormat = emailFormat.toLowerCase();
  // return normalizedEmail.endsWith(normalizedFormat);

  const domainRegex = new RegExp(
    `(${Array.isArray(emailFormat) ? emailFormat.join("|") : emailFormat})$`,
    "i",
  ); // Case-insensitive matching
  return domainRegex.test(email);
}

export function capitalizeEveryWord(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function deepMerge(target: any, ...sources: any[]) {
  if (!sources.length) return target;

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        if (isObject(source[key]) && isObject(target[key])) {
          target[key] = deepMerge(target[key], source[key]);
        } else {
          target[key] = source[key];
        }
      }
    }
  } else if (Array.isArray(target) && Array.isArray(source)) {
    target.push(...source); // or use concat if you want to create a new array instead of modifying the original
  } else if (Array.isArray(target) && !Array.isArray(source)) {
    target.push(source);
  } else if (!Array.isArray(target) && Array.isArray(source)) {
    return source; // if target is not an array and source is, we simply return the source.
  } else {
    target = source; // Overwrite if not objects or arrays
  }

  return deepMerge(target, ...sources);
}

function isObject(item: any) {
  return item !== null && typeof item === "object" && !Array.isArray(item);
}
