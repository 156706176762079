import { Text } from "@/components/ui/Text";
import { Title } from "@/components/ui/Title";
import { Stack } from "@mantine/core";
import { config } from "@/config";
export const SupportPage = () => {
  return (
    <Stack align="center" justify="center" h="100%">
      <Title>Contact support</Title>
      <Text maw={400} ta="center">
        If you need help, have a question, or want to report an issue, please
        email us at{" "}
        <a href={`${config.supportMailtoLink}`} style={{ textDecoration: "underline" }}>
          {config.supportEmail}
        </a> and we'll get back to you as soon as possible.
      </Text>
    </Stack>
  );
};
