import { useStore } from "@/store";
import { useAdvizer, useAdvizerMap } from "../selectors";
import { useSearchParams } from "react-router-dom";
import { Navigation } from "@/services/Navigation";
import { useCallback } from "react";
import { PLAYLIST_OUTCOMES } from "@/configs/playlists/playlistOutcomeConfigs";
import {
  PLAYLIST_STEP_TYPE_CONFIGS,
  USER_PROFILE_DATA_COLLECTION_STEP_TYPES,
} from "@/configs/playlists/playlistStepTypeConfigs";
import { useUserProfileData } from "./userProfileSelectors";

/*===========================================
 Basics 
===========================================*/

export const useActivePlaylistId = () => {
  // const { playlistSlug } = useParams();
  // console.log({ playlistSlug });
  // if (playlistSlug === "start") return "onboarding";
  // return playlistSlug || "";
  const activePlaylistId = useStore(
    (state) => state.playlists.activePlaylistId,
  );

  return activePlaylistId;
};

export const useActivePlaylistStepKeyRaw = () => {
  const [searchParams] = useSearchParams();
  const stepKey = searchParams.get("step");
  return stepKey;
};

export const useActivePlaylistStepKey = () => {
  const activePlaylistStepKeyRaw = useActivePlaylistStepKeyRaw();
  const activePlaylistStepKeyFromStore = useStore(
    (state) => state.playlists.activePlaylistStepKey,
  );
  return activePlaylistStepKeyRaw || activePlaylistStepKeyFromStore || "";
};

export const useActivePlaylistStep = (playlistId?: string) => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist(playlistId || "");

  if (!activePlaylist?.steps) return null;
  return activePlaylist.steps[activePlaylistStepKey];
};

export const useShouldAutoNavToNextStepInPlaylist = () => {
  const [searchParams] = useSearchParams();
  const shouldAutoNav = searchParams.get("autoNav");
  return !!shouldAutoNav && shouldAutoNav !== "false";
};

export const useIsOnboardingPlaylist = () => {
  const activePlaylistId = useActivePlaylistId();
  return activePlaylistId === "onboarding" || activePlaylistId === "start";
};

/*===========================================
  useActivePlaylist
===========================================*/
export const useActivePlaylist = (playlistId?: string) => {
  const isOnboardingPlaylist = useIsOnboardingPlaylist();

  let playlist = useStore((state) => state.playlists.activePlaylist);
  const playlistFromId = usePlaylistById(playlistId || "");
  if (playlistFromId) {
    playlist = playlistFromId;
  }

  const activePlaylistOutcome = useActivePlaylistOutcome();

  if (!playlist) return null;

  let steps = Object.entries(playlist.steps || {}).reduce(
    (acc, [stepKey, stepConfig]: [string, any]) => {
      acc[stepKey] = {
        stepType: stepKey,
        ...PLAYLIST_STEP_TYPE_CONFIGS[stepConfig.stepType],
        ...stepConfig,
      };
      return acc;
    },
    {} as Record<string, any>,
  );

  if (isOnboardingPlaylist) {
    steps = {
      ...steps,
      ...USER_PROFILE_DATA_COLLECTION_STEP_TYPES,
    };
  }

  return {
    ...playlist,
    steps,
    stepOrder: [
      ...(isOnboardingPlaylist
        ? activePlaylistOutcome?.requiredProfileDataSteps || []
        : []),
      ...(playlist?.stepOrder || Object.keys(steps || {})),
    ].sort((a, b) => {
      // make sure "get-suggestions" is last
      if (a === "get-playlists") return 1;
      if (b === "get-playlists") return -1;

      // make sure welcome is first
      if (a === "welcome") return -1;
      if (b === "welcome") return 1;

      return 0;
    }),
  };
};

/*===========================================
 Steps
===========================================*/

export const useFirstStepKeyInPlaylist = () => {
  const activePlaylist = useActivePlaylist();
  return activePlaylist?.stepOrder[0];
};

export const useIsVideoStep = () => {
  const activePlaylistStep = useActivePlaylistStep();
  return activePlaylistStep?.stepType === "video";
};

/*===========================================
 Step completion statuses
===========================================*/

export const useIsPlaylistInProgressForUser = (playlistId: string) => {
  const userPlaylistData = useUserPlaylistData();
  return !!userPlaylistData?.playlists?.[playlistId];
};

export const useIsCurrentStepComplete = (playlistId?: string) => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylistId = useActivePlaylistId();
  const activePlaylistStep = useActivePlaylistStep(playlistId || "");

  // @ts-expect-error need to force render
  const userPlaylistData = useUserPlaylistData();
  // @ts-expect-error need to force render
  const userProfileData = useUserProfileData();

  if (!activePlaylistStep?.isStepComplete) return true;

  return activePlaylistStep?.isStepComplete({
    playlistId: activePlaylistId,
    stepKey: activePlaylistStepKey,
  });
};

export const useStepCompletionStatuses = () => {
  const activePlaylist = useActivePlaylist();
  const activePlaylistId = useActivePlaylistId();
  const stepOrder = activePlaylist?.stepOrder || [];
  const steps = activePlaylist?.steps || {};
  const stepCompletionStatuses = stepOrder
    .map((stepKey: string) => {
      const isStepComplete = steps[stepKey]?.isStepComplete;

      const finalIsStepComplete = isStepComplete
        ? isStepComplete({
            playlistId: activePlaylistId,
            stepKey,
          })
        : true;

      return {
        stepKey,
        isComplete: finalIsStepComplete,
      };
    })
    .reduce(
      (acc: any, status: any) => {
        acc[status.stepKey] = status.isComplete;
        return acc;
      },
      {} as Record<string, boolean>,
    );
  return stepCompletionStatuses;
};

export const useNextIncompleteStepKeyInPlaylist = () => {
  const activePlaylist = useActivePlaylist();
  const stepCompletionStatuses = useStepCompletionStatuses();
  if (!activePlaylist) return null;
  const nextIncompleteStepKey = activePlaylist?.stepOrder.find(
    (stepKey: any) => !stepCompletionStatuses[stepKey],
  );

  if (nextIncompleteStepKey) {
    return nextIncompleteStepKey;
  }

  const lastStepKey =
    activePlaylist?.stepOrder[activePlaylist?.stepOrder.length - 1];

  // return the last step
  return lastStepKey;
};

/*===========================================
  Playlist Duration
===========================================*/

export const useEstimatedPlaylistDuration = (playlistId?: string) => {
  const activePlaylistVideos = useActivePlaylistVideos(playlistId || "");

  if (!activePlaylistVideos.length) return null;
  const totalSeconds = activePlaylistVideos.reduce(
    (acc, video) => acc + (video.duration || 2),
    0,
  );
  const minutes = Math.ceil(totalSeconds / 60);
  return minutes + 1;
};

/*===========================================
  Playlist Progress
===========================================*/

export const useActivePlaylistProgress = () => {
  const activePlaylist = useActivePlaylist();
  const activePlaylistId = useActivePlaylistId();
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const stepOrder = activePlaylist?.stepOrder || [];
  const activePlaylistStepIndex = stepOrder.indexOf(activePlaylistStepKey);
  const totalSteps = stepOrder.length - 1; // excl conclusion
  const percentagePerStep = 100 / totalSteps;

  const totalPercentageComplete = stepOrder.reduce(
    (acc: number, stepKey: string) => {
      const activePlaylistStep = activePlaylist?.steps?.[stepKey];
      if (
        activePlaylistStep?.isStepComplete?.({
          playlistId: activePlaylistId,
          stepKey,
        })
      ) {
        return acc + percentagePerStep;
      }

      const stepIndex = stepOrder.indexOf(stepKey);
      const someStepAfterIsComplete = stepOrder.some(
        (sk: string) =>
          stepOrder.indexOf(sk) > stepIndex &&
          activePlaylist?.steps?.[sk]?.isStepComplete?.({
            playlistId: activePlaylistId,
            stepKey: sk,
          }),
      );

      if (someStepAfterIsComplete) {
        return acc + percentagePerStep;
      }

      if (stepOrder.indexOf(stepKey) < activePlaylistStepIndex) {
        return acc + percentagePerStep;
      }

      return acc;
    },
    0,
  );

  return totalPercentageComplete;
};

/*===========================================
  Playlist Recommendations
===========================================*/

export const useRecommendedPlaylists = () => {
  const recommendedPlaylists = useStore(
    (state) => state.user.playlistData?.recommendedPlaylists,
  );
  return recommendedPlaylists || {};
};

export const useHasRecommendedPlaylists = () => {
  const recommendedPlaylists = useRecommendedPlaylists();
  return Object.keys(recommendedPlaylists).length > 0;
};

export const useIsRecommendedPlaylist = (playlistId: string) => {
  const recommendedPlaylists = useRecommendedPlaylists();
  return recommendedPlaylists?.[playlistId];
};

/*===========================================
  Everything else
===========================================*/

export const usePlaylistLibrary = () => {
  const playlistLibrary = useStore((state) => state.playlists.playlistLibrary);
  return playlistLibrary;
};

export const useAllPlaylists = () => {
  const playlistLibrary = usePlaylistLibrary();
  const allPlaylists = useStore((state) => state.playlists.allPlaylists) || [];
  return allPlaylists.map((playlist: any) => ({
    ...playlist,
    config: playlistLibrary?.[playlist.id],
  }));
};

export const usePlaylistById = (playlistId: string) => {
  const allPlaylists = useAllPlaylists();
  return allPlaylists.find((playlist: any) => playlist.id === playlistId);
};

export const useGoToNextStepInPlaylist = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const nextStepKey = stepOrder[stepOrder.indexOf(activePlaylistStepKey) + 1];

  const func = useCallback(() => {
    if (!nextStepKey) return;

    Navigation.updateQueryParams({ step: nextStepKey });
  }, [nextStepKey]);

  return func;
};

export const useGoToPreviousStepInPlaylist = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const previousStepKey =
    stepOrder[Math.max(0, stepOrder.indexOf(activePlaylistStepKey) - 1)];

  const func = useCallback(() => {
    if (!previousStepKey) return;
    Navigation.updateQueryParams({ step: previousStepKey });
  }, [previousStepKey]);

  return func;
};

export const useUserPlaylistData = () => {
  const playlistData = useStore((state) => state.user.playlistData);
  return playlistData;
};

export const useUserDataForActivePlaylist = () => {
  const activePlaylistId = useActivePlaylistId();
  const userPlaylistData = useUserPlaylistData();

  if (!activePlaylistId) return {};
  return userPlaylistData?.playlists?.[activePlaylistId];
};

export const useUserDataForActivePlaylistStep = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const userPlaylistDataForPlaylist = useUserDataForActivePlaylist();
  return userPlaylistDataForPlaylist?.steps?.[activePlaylistStepKey] || {};
};

export const useUserPlaylistStepData = () => {
  return useUserDataForActivePlaylistStep();
  // const activePlaylistId = useActivePlaylistId();
  // const activePlaylistStepKey = useActivePlaylistStepKey();
  // const userPlaylistData = useUserPlaylistData();

  // return userPlaylistData?.playlists?.[activePlaylistId]?.steps?.[
  //   activePlaylistStepKey
  // ];
};

export const useHidePlaylistBackButton = () => {
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const isFirstStep = useIsFirstStepInPlaylist();

  if (!activePlaylist || isFirstStep) return true;

  return (
    activePlaylist.steps[activePlaylistStepKey]?.hideBackButton || isFirstStep
  );
};

export const useIsFirstStepInPlaylist = () => {
  const activeStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const isOnboardingPlaylist = useIsOnboardingPlaylist();

  if (isOnboardingPlaylist) return false;

  const stepOrder = activePlaylist?.stepOrder || [];
  return stepOrder.indexOf(activeStepKey) === 0;
};

export const useIsLastStepInActivePlaylist = () => {
  const activeStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const index = stepOrder.indexOf(activeStepKey);
  return index === stepOrder.length - 1;
};

export const useIsSecondToLastStepInActivePlaylist = () => {
  const activeStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  const stepOrder = activePlaylist?.stepOrder || [];
  const index = stepOrder.indexOf(activeStepKey);
  return index === stepOrder.length - 2;
};

export const useActivePlaylistStepParams = () => {
  const activeStepKey = useActivePlaylistStepKey();
  const activePlaylist = useActivePlaylist();
  return activePlaylist?.steps?.[activeStepKey]?.stepParams;
};

export const useActivePlaylistAdvizer = () => {
  const activePlaylistStepParams = useActivePlaylistStepParams();
  const advizerId = activePlaylistStepParams?.advizerId;
  const advizer = useAdvizer(advizerId);
  return advizer;
};

export const usePlaylistVideo = (params: {
  advizerId: string;
  videoQuestionNumber: number;
}) => {
  const { advizerId, videoQuestionNumber } = params;
  const advizer = useAdvizer(advizerId);
  if (!advizer || !videoQuestionNumber) return null;
  const video = advizer.videos.find(
    (v) => v.questionNumber === videoQuestionNumber,
  );

  return video;
};

export const useActivePlaylistOutcomeKey = () => {
  const activePlaylistOutcomeKey = useStore(
    (state) => state.playlists.activePlaylistOutcomeKey,
  );

  // const isOnboardingPlaylist = useIsOnboardingPlaylist();
  // if (!isOnboardingPlaylist) return null; // TODO remove this

  return activePlaylistOutcomeKey || "narrow-down-career-options";
};

export const useActivePlaylistOutcome = () => {
  const activePlaylistOutcomeKey = useActivePlaylistOutcomeKey();
  const activePlaylistOutcome =
    PLAYLIST_OUTCOMES[
      activePlaylistOutcomeKey as keyof typeof PLAYLIST_OUTCOMES
    ];
  return { ...activePlaylistOutcome, outcomeKey: activePlaylistOutcomeKey };
};

export const useActivePlaylistVideo = () => {
  const stepParams = useActivePlaylistStepParams();
  const video = usePlaylistVideo({
    advizerId: stepParams?.advizerId,
    videoQuestionNumber: stepParams?.videoQuestionNumber,
  });
  return video;
};

export const useActivePlaylistAdvizers = (playlistId?: string) => {
  const activePlaylist = useActivePlaylist(playlistId || "");
  const advizerIds = Object.values(activePlaylist?.steps || {})
    .map((step: any) => step.stepParams?.advizerId)
    .filter(Boolean);
  const advizerMap = useAdvizerMap();
  const advizers = advizerIds
    .map((id) => advizerMap[id])
    .filter((advizer) => advizer !== undefined);

  return advizers;
};

export const useActivePlaylistVideos = (playlistId?: string) => {
  const activePlaylistId = useActivePlaylistId();
  const activePlaylist = useActivePlaylist(
    playlistId || activePlaylistId || undefined,
  );
  const advizers = useActivePlaylistAdvizers(
    playlistId || activePlaylistId || undefined,
  );

  const videos: any[] = [];

  advizers.forEach((advizer) => {
    const matchingStep: any = Object.values(activePlaylist?.steps || {}).find(
      (step: any) => step.stepParams?.advizerId === advizer.id,
    );

    if (matchingStep) {
      const video = advizer.videos.find(
        (v: any) =>
          v.questionNumber === matchingStep.stepParams?.videoQuestionNumber,
      );
      if (video) {
        videos.push(video);
      }
    }
  });

  return videos;
};
