import { useStore } from "@/store";
import { Stack, Textarea } from "@mantine/core";

export const PlaylistCustomRequest = () => {
  const userCustomPlaylistRecommendationRequest = useStore(
    (state) => state.playlists.userCustomPlaylistRecommendationRequest,
  );
  const updateUserCustomPlaylistRecommendationRequest = useStore(
    (state) => state.playlists.updateUserCustomPlaylistRecommendationRequest,
  );

  return (
    <Stack>
      <Textarea
        placeholder={`This can be anything! Some examples... 
- I'm not sure if becoming a lawyer is right for me
- I'm applying to a job at a manufacturing company and need help prepping for the interview`}
        size="lg"
        autosize
        minRows={5}
        maxLength={500}
        value={userCustomPlaylistRecommendationRequest}
        onChange={(e) =>
          updateUserCustomPlaylistRecommendationRequest(e.target.value)
        }
      />
    </Stack>
  );
};
