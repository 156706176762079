import { User } from "@/models/User";
import { useStore as store } from "@/store";
import { IStoreState } from "@/types/StoreTypes";

class StoreServiceClass {
  constructor() {}

  getState() {
    return store.getState();
  }

  getPlaylistState() {
    return store.getState().playlists;
  }

  getActivePlaylistStepKey() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const stepKey = urlSearchParams.get("step");
    if (stepKey) return stepKey;
    return this.getPlaylistState().activePlaylistStepKey;
  }

  getUserState() {
    return store.getState().user;
  }

  createStateUpdater(fn: (state: IStoreState) => void, config: any) {
    return store.getState().createStateUpdater(fn, config);
  }

  updatePlaylistState(state: Partial<IStoreState["playlists"]>) {
    this.getPlaylistState().updatePlaylistsState(state);
  }

  getActiveUser() {
    const userRaw = store.getState().user?.activeUser;
    if (!userRaw) return null;
    return new User(userRaw);
  }

  updateActiveUser(data: Partial<IStoreState["user"]>) {
    this.getUserState().updateActiveUser(data);
  }

  getUserPlaylistData() {
    return this.getUserState().playlistData;
  }

  getUserProfileData() {
    return this.getUserState().profileData;
  }

  setPlaylistLibrary(playlistLibrary: any) {
    this.getPlaylistState().setPlaylistLibrary(playlistLibrary);
  }

  setAllPlaylists(allPlaylists: any) {
    this.getPlaylistState().setAllPlaylists(allPlaylists);
  }

  setDidLoadUserData(didLoadUserData: boolean) {
    this.getUserState().setDidLoadUserData(didLoadUserData);
  }

  clearActivePlaylist() {
    this.getPlaylistState().clearActivePlaylist();
    this.getPlaylistState().setActivePlaylistStepKey(null);
  }
}

export const StoreService = new StoreServiceClass();
