import { Stack } from "@mantine/core";
import { useActivePlaylistId } from "@/store/selectors/playlistSelectors";
import { PlaylistCard } from "./PlaylistCard";

export const PlaylistIntro = () => {
  const activePlaylistId = useActivePlaylistId();

  if (!activePlaylistId) return null;

  return (
    <Stack maw={550}>
      <PlaylistCard playlistId={activePlaylistId} isPlaylistIntro />
    </Stack>
  );

  // return (
  //   <Stack gap={0} align="flex-start" maw={400}>
  //     <Space h="md" />

  //     <Title order={1} c="text.9" lh={1.1} ta="left" size="h2">
  //       {activePlaylist?.title}
  //     </Title>

  //     <Space h={8} />

  //     {estimatedPlaylistDuration && (
  //       <Group gap={4} align="center" justify="flex-start" w="100%">
  //         <IconClock size={16} color={theme.colors.text[7]} />
  //         <Text size="sm" c="text.7">
  //           {estimatedPlaylistDuration} minutes
  //         </Text>
  //       </Group>
  //     )}

  //     <Space h="xs" />
  //     <Text size="sm" c="text.7" ta="left" maw={400} lh={1.5}>
  //       {activePlaylist?.description}
  //     </Text>

  //     <Space h="xl" />

  //     <Stack>
  //       <PlaylistAdvizerList />
  //     </Stack>

  //     {/* <Image
  //       src={playlistConfig.imageSrc}
  //       alt={`${playlistConfig.title} Image`}
  //       w="100%"
  //       h={200}
  //       fit="contain"
  //       ratio={16 / 9}
  //       radius="md"
  //     /> */}
  //   </Stack>
  // );
};
