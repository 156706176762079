import { Group } from "@mantine/core";
import { Text } from "./ui/Text";
import { useStyles } from "@/styles/useStyles";
import { config } from "@/config";
import { useLocation } from "react-router-dom";
import { Link } from "./ui/Link";
import { Analytics } from "@/services/Analytics";

export const Banner = () => {
  const { theme } = useStyles();
  const { pathname } = useLocation();
  const isPlaylistPage = pathname.includes("playlists");

  if (!isPlaylistPage) return null;

  return (
    <Group
      w="100vw"
      px="md"
      py="md"
      bg="background.2"
      justify="center"
      align="center"
      ta="center"
      c="white"
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        // opacity: 0.8,
        right: 0,
        zIndex: 1000,
        borderTop: `1px solid ${theme.colors.border[4]}`,
      }}
    >
      <Text titleStyle="h6" w="100%" c="text.7" pl="xs">
        Playlists are a work-in-progress! Have feedback?{" "}
        <Link
          to={`${config.supportEmailLink}`}
          style={{ textDecoration: "underline" }}
          target="_blank"
          onClick={() => {
            Analytics.click({
              action: "Contact Support from banner",
              label: "Click here to contact support",
              context: "feature-flag-banner",
            });
          }}
        >
          Click here to contact support.
        </Link>
      </Text>
    </Group>
  );
};
