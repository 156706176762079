import { PlaylistConclusion } from "@/components/playlists/PlaylistConclusion";
import { PlaylistCustomRequest } from "@/components/playlists/PlaylistCustomRequest";
import { PlaylistIndustrySelector } from "@/components/playlists/PlaylistIndustrySelector";
import { PlaylistInitialOptions } from "@/components/playlists/PlaylistInitialOptions";
import { PlaylistIntro } from "@/components/playlists/PlaylistIntro";
import { PlaylistMajorSelector } from "@/components/playlists/PlaylistMajorSelector";
import { PlaylistOutcomeOptions } from "@/components/playlists/PlaylistOutcomeOptions";
import { PlaylistProfileBuilder } from "@/components/playlists/PlaylistProfileBuilder";
import { PlaylistScale } from "@/components/playlists/PlaylistScale";
import { PlaylistVideo } from "@/components/playlists/PlaylistVideo";
import { RecommendPlaylists } from "@/components/playlists/RecommendPlaylists";
import { config } from "@/config";
import { Analytics } from "@/services/Analytics";
import { Experience } from "@/services/Experience";
import { PlaylistService } from "@/services/PlaylistService";
import { StoreService } from "@/services/StoreService";
import { UserProfileService } from "@/services/UserProfileService";

export const USER_PROFILE_DATA_COLLECTION_STEP_TYPES: Record<
  string,
  Omit<IPlaylistStepConfig, "stepType">
> = {
  "select-subjects": {
    title: "What subjects are you studying or interested in?",
    Component: PlaylistMajorSelector,
    isProfileDataCollection: true,
    hideBackButton: true,
    disabledButtonText: "Pick at least 1",
    isStepComplete: () => {
      return UserProfileService.getUserHasSelectedSubjects();
    },
  },
  "select-industries": {
    title: "What industries might you want to work in?",
    Component: PlaylistIndustrySelector,
    isProfileDataCollection: true,
    hideBackButton: false,
    disabledButtonText: "Pick at least 1",
  },
  "custom-request": {
    title:
      "Anything else you'd like me to consider before recommending playlists?",
    Component: PlaylistCustomRequest,
    nextButtonText: "Get playlists",
    onNextButtonClick: () => {
      PlaylistService.clearUserRecommendedPlaylists();

      const customRequest =
        StoreService.getPlaylistState().userCustomPlaylistRecommendationRequest;
      console.log({ customRequest });
      if (customRequest) {
        Analytics.updateUserProfile({
          action: "custom_playlist_request",
          label: customRequest,
        });
      }
    },
  },
};

export const PLAYLIST_STEP_TYPE_CONFIGS: Record<
  string,
  Omit<IPlaylistStepConfig, "stepType">
> = {
  ...USER_PROFILE_DATA_COLLECTION_STEP_TYPES,
  welcome: {
    title: `Hey! I’m Emily. Watch ${config.dbTotals.videos.toLocaleString()}+ short interview videos from ${config.dbTotals.advizers.toLocaleString()}+ working professionals to build your career confidence and land a job you'll love.`,
    nextButtonText: `Let's get started`,
    hideBackButton: true,
    hideNextButton: true,
    onNextButtonClick: () => {
      Experience.showConfetti();
      // Experience.setExplicitPlaylistMessage({
      //   message: "Wohoo! Starting's the hard part.",
      //   timeout: 1800,
      // });
    },
    Component: PlaylistInitialOptions,
    // ComponentAfterNextButton: PlaylistHowItWorks,
  },
  "select-outcome": {
    title: "What do you want to accomplish today?",
    hideNextButton: true,
    hideBackButton: true,
    Component: PlaylistOutcomeOptions,
  },
  "build-profile-for-playlists": {
    // title: "Building your profile...",
    Component: PlaylistProfileBuilder,
  },
  "get-playlists": {
    title: "Finding the best playlists for you...",
    Component: RecommendPlaylists,
    hideNextButton: true,
    hideBackButton: true,
    isStepComplete: () => {
      return true;
    },
  },
  "playlist-intro": {
    nextButtonText: "Start playlist",
    hideNextButton: true,
    Component: PlaylistIntro,
  },
  "scale-1-to-5": {
    Component: PlaylistScale,
    isStepComplete: ({ playlistId, stepKey }) => {
      const playlistStepData = PlaylistService.getUserPlaylistStepData({
        playlistId,
        stepKey,
      });

      return !!playlistStepData?.value;
    },
  },
  video: {
    Component: PlaylistVideo,
  },
  "playlist-conclusion": {
    Component: PlaylistConclusion,
    hideNextButton: true,
    hideBackButton: true,
  },
};

export type IPlaylistStepConfig = {
  title?: string;
  stepType?: keyof typeof PLAYLIST_STEP_TYPE_CONFIGS;
  Component?: React.ComponentType<any>;
  ComponentAfterNextButton?: React.ComponentType<any>;
  onNextButtonClick?: () => void;
  nextButtonText?: string;
  hideNextButton?: boolean;
  hideBackButton?: boolean;
  isProfileDataCollection?: boolean;
  isVideo?: boolean;
  stepParams?: Record<string, any>;
  disabledButtonText?: string;
  isStepComplete?: ({
    playlistId,
    stepKey,
  }: {
    playlistId: string;
    stepKey: string;
  }) => boolean;
};
