import {
  useActivePlaylistStep,
  useActivePlaylistStepKey,
  useGoToNextStepInPlaylist,
  useGoToPreviousStepInPlaylist,
  useIsCurrentStepComplete,
  useIsFirstStepInPlaylist,
  useIsOnboardingPlaylist,
  useIsSecondToLastStepInActivePlaylist,
  useIsVideoStep,
} from "@/store/selectors/playlistSelectors";
import { Group, lighten, Space, Stack } from "@mantine/core";
import { PlaylistTopBar } from "./PlaylistTopBar";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "@/store";
import { Title } from "../ui/Title";
import { Button } from "../ui/Button";
import { IconArrowRight } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import { IPlaylistStepConfig } from "@/configs/playlists/playlistStepTypeConfigs";
import { Experience } from "@/services/Experience";
import { Analytics } from "@/services/Analytics";

const scrollLessThanMin = 50;
const maxWidth = 650;
export const Playlist = (props: { children?: React.ReactNode }) => {
  const { theme, ...styles } = useStyles();
  const showMainNavbar = useStore((state) => state.showMainNavbar);
  const setShowMainNavbar = useStore((state) => state.setShowMainNavbar);
  const activeStep = useActivePlaylistStep();
  const activeStepKey = useActivePlaylistStepKey();
  const [latestScrollTop, setLatestScrollTop] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const setMostRecentPathname = useStore(
    (state) => state.setMostRecentPathname,
  );
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const isOnboardingPlaylist = useIsOnboardingPlaylist();
  const goToNextStepInPlaylist = useGoToNextStepInPlaylist();
  const isSecondToLastStepInActivePlaylist =
    useIsSecondToLastStepInActivePlaylist();
  const setDidScrollDown = useStore((state) => state.setDidScrollDown);
  const isVideoStep = useIsVideoStep();
  // const activePlaylist = useActivePlaylist();
  // const setNavPathAfterLogin = useStore((state) => state.setNavPathAfterLogin);

  const handleClickNextButton = useCallback(() => {
    goToNextStepInPlaylist();

    if (isSecondToLastStepInActivePlaylist && !isOnboardingPlaylist) {
      Experience.showConfetti();
      Analytics.playlistComplete();
    }
  }, [
    goToNextStepInPlaylist,
    isSecondToLastStepInActivePlaylist,
    isOnboardingPlaylist,
  ]);

  useEffect(() => {
    if (pathname.includes("/playlist")) {
      const fullPathname = `${pathname}${location.search}`;
      setMostRecentPathname(fullPathname);
    }
  }, [pathname, setMostRecentPathname]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [activePlaylistStepKey]);

  const handleScroll = useCallback(
    (e: any) => {
      if (isScrolling) return;

      setIsScrolling(true);
      setTimeout(() => setIsScrolling(false), 10);

      const currentScroll = e.target.scrollTop;
      const scrollingDown = currentScroll > latestScrollTop;
      const isScollLessThanMinNew = currentScroll < scrollLessThanMin;

      if (scrollingDown) {
        setDidScrollDown(true);
        setShowMainNavbar(false);
      } else if (isScollLessThanMinNew) {
        setShowMainNavbar(true);
      }

      // if (scrollingDown) {
      //   // setDidScrollDown(false);
      //   setShowMainNavbar(false);
      // }

      // // Always show navbar when near top
      // if (isScollLessThanMinNew) {
      //   setShowMainNavbar(true);
      //   setIsScollLessThanMin(true);
      //   // setLatestScrollTop(currentScroll);
      //   return;
      // }

      // Only update navbar visibility on significant scroll
      // if (Math.abs(currentScroll - latestScrollTop) > 5) {
      //   setShowMainNavbar(!scrollingDown);
      // }

      setLatestScrollTop(currentScroll);
    },
    [isScrolling, latestScrollTop, setDidScrollDown, setShowMainNavbar],
  );

  return (
    <Group
      pt={!showMainNavbar ? 0 : styles.dimensions.headerHeight}
      pb={100}
      gap={0}
      align="start"
      h="100%"
      // h="100vh"
      // mah="100vh"
      style={{ overflow: "scroll" }}
      onScroll={handleScroll}
      ref={scrollContainerRef}
      bg={lighten(theme.colors.background[1], 0.5)}
    >
      <Stack
        py={0}
        gap={0}
        flex={1}
        ta="center"
        align="center"
        w="100%"
        justify="center"
      >
        <PlaylistTopBar
          hideAvatar={!showMainNavbar}
          maxWidth={maxWidth}
          handleClickNextButton={handleClickNextButton}
          bg={lighten(theme.colors.background[1], 0.5)}
        />
        {activeStep ? (
          <Stack
            py={70}
            pt={isVideoStep ? scrollLessThanMin : 70}
            px={activeStep?.isVideo ? "sm" : "lg"}
            gap={0}
            flex={1}
            align="center"
            justify="space-between"
            maw={maxWidth}
            w="100%"
          >
            <PlaylistStepContent
              stepConfig={activeStep}
              animationKey={activeStepKey}
              children={props.children}
              handleClickNextButton={handleClickNextButton}
            />
          </Stack>
        ) : null}
      </Stack>
    </Group>
  );
};

export const PlaylistStepContent = (props: {
  children?: React.ReactNode;
  stepConfig: IPlaylistStepConfig;
  animationKey: string;
  handleClickNextButton?: () => void;
}) => {
  const { stepConfig, handleClickNextButton } = props;
  const activeStep = useActivePlaylistStep();
  const activePlaylistStepKey = useActivePlaylistStepKey();
  const isPlaylistLoading = useStore(
    (state) => state.playlists.isPlaylistLoading,
  );
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const goToPreviousStepInPlaylist = useGoToPreviousStepInPlaylist();
  const { nextButtonText, onNextButtonClick } = props?.stepConfig || {};
  const isFirstStepInPlaylist = useIsFirstStepInPlaylist();
  const hideBackButton = isFirstStepInPlaylist || stepConfig?.hideBackButton;
  const explicitPlaylistMessage = useStore(
    (state) => state.playlists.explicitPlaylistMessage,
  );
  const isCurrentStepComplete = useIsCurrentStepComplete();
  const disableNextButton = !isCurrentStepComplete;
  const isAfterStep = activePlaylistStepKey.endsWith("-after");
  const isOnboardingPlaylist = useIsOnboardingPlaylist();
  const isSecondToLastStepInActivePlaylist =
    useIsSecondToLastStepInActivePlaylist();
  const mainMessage = useMemo(() => {
    return (
      explicitPlaylistMessage ||
      (typeof isPlaylistLoading === "string"
        ? isPlaylistLoading
        : activeStep?.title)
    );
  }, [explicitPlaylistMessage, isPlaylistLoading, activeStep?.title]);

  const handleClickBackButton = useCallback(() => {
    goToPreviousStepInPlaylist();
  }, [goToPreviousStepInPlaylist]);

  const mainMessageText = useMemo(() => {
    if (isAfterStep) {
      // take the mainMessage and add  ", now" before the question mark
      const [question] = mainMessage?.split("?") || [];
      return (
        <span>
          {question}{" "}
          <span style={{ textDecoration: "underline" }}>
            after watching the playlist
          </span>
          ?
        </span>
      );
    }

    return mainMessage;
  }, [isAfterStep, mainMessage]);

  // if (!playlist) return null;

  // if (props.children) return props.children;

  return (
    <Stack gap={0} align="center" p={0}>
      {mainMessage && (
        <Stack gap="md">
          <Title
            order={1}
            size={isLaptopOrBigger ? "h3" : "h3"}
            c="slate.9"
            lh={1.3}
            fw={700}
            fancyFont
            maw={isOnboardingPlaylist ? 530 : 450}
          >
            {mainMessageText}
          </Title>
        </Stack>
      )}
      {/* </motion.div>
      </AnimatePresence> */}

      {isPlaylistLoading ? null : (
        <>
          {activeStep?.isVideo || !activeStep?.title ? null : <Space h="lg" />}

          {/* {activeStep?.Component ? ( */}
          <Stack p={8} px={0} w="100%">
            <activeStep.Component {...activeStep.stepParams} />
          </Stack>
          {/* ) : null} */}

          {activeStep?.Component ? <Space h="xl" /> : null}
          <Stack w="100%" align="center">
            {!activeStep?.hideNextButton && (
              <Button
                fullWidth
                radius="xl"
                size="lg"
                onClick={() => {
                  handleClickNextButton?.();
                  onNextButtonClick?.();
                }}
                rightSection={<IconArrowRight />}
                maw={300}
                disabled={disableNextButton}
              >
                {disableNextButton
                  ? stepConfig?.disabledButtonText || "Next"
                  : isSecondToLastStepInActivePlaylist
                    ? nextButtonText || "Finish"
                    : nextButtonText || "Next"}
              </Button>
            )}
            {hideBackButton ? null : (
              <Button
                fullWidth
                variant="transparent"
                disabled={hideBackButton}
                size="xs"
                c="slate.7"
                onClick={handleClickBackButton}
              >
                Go back
              </Button>
            )}
          </Stack>

          {activeStep?.ComponentAfterNextButton && (
            <Stack p={8} px={0} w="100%" gap={0}>
              {<activeStep.ComponentAfterNextButton />}
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
