import {
  useActivePlaylistAdvizers,
  useActivePlaylistVideos,
} from "@/store/selectors/playlistSelectors";
import { motion, AnimatePresence } from "framer-motion";
import { useStyles } from "@/styles/useStyles";
import { AspectRatio, Avatar, Group, Stack } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { Button } from "../ui/Button";
import { useAdvizerQuotes } from "@/store/selectors";
import { AdvizerQuotes } from "../AdvizerQuotes";

export const PlaylistAdvizerList = ({
  playlistId,
  maxAdvizerListHeight,
  advizerQuoteLineClamp,
}: {
  playlistId?: string;
  maxAdvizerListHeight?: number;
  advizerQuoteLineClamp?: number;
}) => {
  const advizerList = useActivePlaylistAdvizers(playlistId);
  const [currentAdvizerIndex, setCurrentAdvizerIndex] = useState(0);
  const { theme } = useStyles();
  const videos = useActivePlaylistVideos(playlistId);

  const activeAdvizerInList = useMemo(() => {
    return advizerList[currentAdvizerIndex];
  }, [currentAdvizerIndex, advizerList]);

  const quotes = useAdvizerQuotes(activeAdvizerInList?.id);
  const activeAdvizerVideo = useMemo(() => {
    return videos.find((v) => activeAdvizerInList.id === v.advizerId);
  }, [activeAdvizerInList?.id, videos]);
  const activeAdvizerVideoQuote = useMemo(() => {
    return quotes.find((q) => activeAdvizerVideo?.id === q.videoId);
  }, [activeAdvizerVideo?.id, quotes]);

  useEffect(() => {
    // rotate active advizer index every 3 seconds until user manually intervense
    const interval = setInterval(() => {
      setCurrentAdvizerIndex((prev) => (prev + 1) % advizerList.length);
    }, 4500);
    return () => clearInterval(interval);
  }, [activeAdvizerInList, advizerList.length]);

  return (
    <Stack align="space-between" w="100%">
      <Group justify="flex-start" w="100%">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentAdvizerIndex} // Use a unique key to trigger animation
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              height: maxAdvizerListHeight,
            }}
          >
            <AdvizerQuotes
              advizer={activeAdvizerInList}
              activeQuote={activeAdvizerVideoQuote}
              lineClamp={advizerQuoteLineClamp}
              withAdvizerName
              // maxHeight={150}
            />
          </motion.div>
        </AnimatePresence>
      </Group>
      <Group gap="xs" w="100%" pl="lg">
        {advizerList.map((advizer, index) => {
          if (!advizer) return null;
          const isActive = index === currentAdvizerIndex;
          return (
            <Button
              key={advizer.id || index}
              unstyled
              onClick={() => setCurrentAdvizerIndex(index)}
              opacity={isActive ? 1 : 0.55}
              style={{
                transform: isActive ? "scale(1.2)" : "scale(1)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <AspectRatio ratio={1}>
                <Avatar
                  src={advizer.profilePictureURL}
                  w="100%"
                  h="100%"
                  maw={40}
                  mah={40}
                  style={{
                    border: `1px solid ${theme.colors.border[7]}`,
                  }}
                />
              </AspectRatio>
            </Button>
          );
        })}
      </Group>
    </Stack>
  );
};
