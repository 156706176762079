import { NAV_ITEMS } from "@/configs/navItems";
import { useActiveUser, useActiveUserPartner } from "../selectors";

export const useShowPlaylists = () => {
  const activeUser = useActiveUser();
  const activeUserPartner = useActiveUserPartner();

  if (!activeUser || !activeUserPartner) return false;

  const hasNumberInEmail = /\d/.test(activeUser.email || "");

  return (
    // activeUserPartner.slug.includes("asu") &&
    activeUser.email.includes("asu.edu") && !hasNumberInEmail
    // &&activeUser.email.includes("yosttest")
  );
};

export const useNavItems = () => {
  const showPlaylists = useShowPlaylists();

  return NAV_ITEMS.filter((navItem) => {
    if (navItem.href === "/playlists") {
      return showPlaylists;
    }
    return true;
  });

  // return NAV_ITEMS.filter((navItem) => {
  //   return navItem.showOnDesktopNavbar === showPlaylists;
  // });
};
