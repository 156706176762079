import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "@/store";

export const UiUpdates = () => {
  const { pathname } = useLocation();
  const isExplorePath = pathname === "/explore";
  const setIsInlineAdvizerSearchOpen = useStore(
    (state) => state.advizerSearch.setIsInlineAdvizerSearchOpen,
  );

  const setCollapseHeader = useStore(
    (state) => state.advizerSearch.setCollapseHeader,
  );

  useEffect(() => {
    setCollapseHeader(false);
  }, [pathname, setCollapseHeader]);

  useEffect(() => {
    if (isExplorePath) {
      setIsInlineAdvizerSearchOpen(true);
    }
  }, []);

  return null;
};
