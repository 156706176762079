import axios from "axios";
import { config } from "@/config";

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export type IGenericApiQueryParams = {
  model?: string;
  include?: Record<string, any>;
  where?: Record<string, any>;
  take?: number;
  skip?: number;
  noCache?: boolean;
  cacheInvalidator?: number;
};

const NO_CACHE = false;
class AdvizeApiClass {
  async recommendPlaylists(params: any) {
    const response = await api.post("/recommend-playlists", params);
    // return response.data;
    console.log({ response });
    return (response.data?.data?.items || []).reverse();
  }

  async query(params: IGenericApiQueryParams) {
    const response = await api.get("/query", {
      params: {
        ...params,
        cacheInvalidator: config.cacheInvalidator,
        // staticFileOnly: !config.isLocalClient,
        noCache: config.isLocalClient && NO_CACHE,
      },
    });

    const items = response.data.data || response.data;

    return { data: { items } };
  }

  async queryPartners(params?: IGenericApiQueryParams) {
    return this.query({ model: "partner", ...params });
  }

  async querySubjects(params?: IGenericApiQueryParams) {
    return this.query({ model: "subject", ...params });
  }

  async queryJobFunctions(params?: IGenericApiQueryParams) {
    return this.query({ model: "jobFunction", ...params });
  }

  async queryTraits(params?: IGenericApiQueryParams) {
    return this.query({ model: "trait", ...params });
  }

  async queryIndustries(params?: IGenericApiQueryParams) {
    return this.query({ model: "industry", ...params });
  }

  async queryQuestions(params?: IGenericApiQueryParams) {
    return this.query({
      model: "question",
      include: { partners: true },
      ...params,
    });
  }

  async queryDescriptors(params?: IGenericApiQueryParams) {
    return this.query({ model: "descriptor", ...params });
  }

  async queryQuotes(params?: IGenericApiQueryParams) {
    return this.query({ model: "quote", ...params });
  }

  async queryBasicAdvizers(params?: IGenericApiQueryParams) {
    return this.query({
      model: "advizer",
      ...params,
      include: {
        interviews: {
          include: {
            videos: { include: { question: true } },
          },
        },
        partners: true,
        subjects: true,
        traits: true,
        industries: true,
        jobFunctions: true,
      },
    });
  }

  async queryAdvizersWithEverything(params?: IGenericApiQueryParams) {
    return this.query({
      model: "advizer",
      ...params,
      include: {
        interviews: {
          include: {
            videos: { include: { question: true, descriptors: false } },
            // videos: {
            //   include: {
            //     question: false,
            //     descriptors: { select: { id: true, text: true } },
            //   },
            // },
          },
        },
        partners: true,
        subjects: true,
        traits: true,
        industries: true,
        jobFunctions: true,
        quotes: false,
      },
    });
  }
}

export const AdvizeApi = new AdvizeApiClass();
