interface IAnalyticsEventConfig {
  title: string;
  excludeFromFirestore?: boolean;
  anonymous?: boolean;
}

export const ANALYTICS_EVENTS: Record<string, IAnalyticsEventConfig> = {
  click: {
    title: "Click",
    excludeFromFirestore: true,
  },
  invalid_email: {
    title: "Invalid Email",
  },
  login_attempt: {
    title: "Login Attempt",
  },
  login: {
    title: "Login",
  },
  logout: {
    title: "Logout",
  },
  register: {
    title: "Register",
    anonymous: true,
  },
  error: {
    title: "Error",
    anonymous: true,
  },
  filter_advizers: {
    title: "Filter Advizers",
  },
  no_advizers_found: {
    title: "No Advizers Found",
  },
  video_start: {
    title: "Video Start",
  },
  video_progress: {
    title: "Video Progress",
  },
  video_progress_every_5_seconds: {
    title: "Video Progress Every 5 Seconds",
  },
  video_pause: {
    title: "Video Pause",
  },
  video_stop: {
    title: "Video Stop",
  },
  video_seek: {
    title: "Video Seek",
  },
  video_end: {
    title: "Video End",
  },
  accept_cookies: {
    title: "Accept Cookies",
  },
  reject_cookies: {
    title: "Reject Cookies",
    anonymous: true,
  },
  video_error: {
    title: "Video Error",
    anonymous: true,
  },
  page_404: {
    title: "404 Page Not Found",
    anonymous: true,
  },
  update_user_profile: {
    title: "Update User Profile",
  },
  filter_playlists: {
    title: "Filter Playlists",
  },
  playlist_start: {
    title: "Playlist Start",
  },
  playlist_complete: {
    title: "Playlist Complete",
  },
  get_recommended_playlists: {
    title: "Get Recommended Playlists",
  },
  playlist_scale_select: {
    title: "Playlist Scale Select",
  },
  playlist_error: {
    title: "Playlist Error",
  },
  // playlist_step_start: {
  //   title: "Playlist Step Start",
  // },
};
