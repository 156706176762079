import { useIndustryFilterOptions } from "@/store/selectors";
import { FilterOptionChip } from "../AdvizerSearchFilters";
import { ResourceConfigs } from "@/configs/resourceConfigs";
import { Group, Stack } from "@mantine/core";
import { useMemo, useState } from "react";
import { TextInput } from "../ui/TextInput";
import { IconSearch } from "@tabler/icons-react";
import { useIsLaptopOrBigger } from "@/styles/useStyles";
import { UserProfileService } from "@/services/UserProfileService";
import { useUserIndustries } from "@/store/selectors/userProfileSelectors";
import { Analytics } from "@/services/Analytics";

export const PlaylistIndustrySelector = () => {
  const { industries } = useIndustryFilterOptions();
  const [search, setSearch] = useState("");
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const userIndustries = useUserIndustries();

  const filteredItems = useMemo(() => {
    return industries.filter((industry) =>
      industry.title.toLowerCase().includes(search.toLowerCase()),
    );
  }, [industries, search]);

  const handleSelectIndustry = (option: any) => {
    const alreadySelected = userIndustries?.[option.id];
    if (alreadySelected) {
      UserProfileService.removeIndustry({ id: option.id });
    } else {
      UserProfileService.addIndustry({
        id: option.id,
        title: option.label,
      });

      Analytics.updateUserProfile({
        action: "add_industry",
        label: option.label,
      });
    }
  };

  return (
    <Stack align="center" w="100%">
      <TextInput
        id="partnerSearchInput"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search"
        size={isLaptopOrBigger ? "sm" : "md"}
        radius="xl"
        leftSection={<IconSearch size={18} />}
        maw={350}
        w="100%"
      />

      <Group justify="flex-start" gap="xs" maw={600}>
        {filteredItems.map((item) => (
          <FilterOptionChip
            key={item.id}
            size={isLaptopOrBigger ? "sm" : "sm"}
            checked={userIndustries?.[item.id]}
            tab={{
              value: "industry",
              filterKey: "industries",
              onClick: (option) => {
                handleSelectIndustry(option);
              },
            }}
            option={{ id: item.id, label: item.title }}
            resourceConfig={ResourceConfigs.industry}
          />
        ))}
      </Group>
    </Stack>
  );
};
