import { useStore } from "@/store";

export const useUserProfileData = () => {
  return useStore((state) => state.user?.profileData);
};

export const useUserSubjects = () => {
  const profileData = useUserProfileData();
  return profileData?.subjects || {};
};

export const useUserHasSelectedSubjects = () => {
  const subjects = useUserSubjects();
  return Object.keys(subjects).length > 0;
};

export const useUserIndustries = () => {
  const profileData = useUserProfileData();
  return profileData?.industries || {};
};

export const useUserHasSelectedIndustries = () => {
  const industries = useUserIndustries();
  return Object.keys(industries).length > 0;
};
