import { useParams } from "react-router-dom";
import { useIsTablet, useIsDesktop, useIsLaptop } from "@/styles/useStyles";
import {
  isDesktop as isDesktopDevice,
  isMobile as isMobileDevice,
} from "react-device-detect";
export { useStore } from "@/store";

export const useIsAdvizerProfilePage = () => {
  const { advizerId } = useParams();
  return !!advizerId;
};

export const useValueFromBreakpoint = (
  valuesByBreakpoint: Record<string, any>,
) => {
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isLaptop = useIsLaptop();

  if (isTablet && valuesByBreakpoint.tablet) {
    return valuesByBreakpoint.tablet;
  }

  if (isLaptop && valuesByBreakpoint.laptop) {
    return valuesByBreakpoint.laptop;
  }

  if (isDesktop && valuesByBreakpoint.desktop) {
    return valuesByBreakpoint.desktop;
  }

  return valuesByBreakpoint.mobile;
};

export const useDeviceType = () => {
  return {
    isMobileDevice,
    isDesktopDevice,
  };
};
