import { StoreService } from "@/services/StoreService";
import { RealtimeDatabase } from "./RealtimeDatabase";

class UserProfileServiceClass {
  getUserProfileData() {
    const userProfileData = StoreService.getUserState().profileData;
    return userProfileData;
  }

  addSubject({ id, title }: { id: string; title: string }) {
    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.addCurrentUserSubject({
        subjectId: id,
        subjectData: {
          id,
          title,
        },
      });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        draft.user.profileData.subjects = {
          ...draft.user.profileData.subjects,
          [id]: {
            id,
            title,
          },
        };
      },
      { saveLocal: true },
    );
  }

  removeSubject({ id }: { id: string }) {
    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.removeCurrentUserSubject({ subjectId: id });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        delete draft.user.profileData.subjects[id];
      },
      { saveLocal: true },
    );
  }

  addIndustry({ id, title }: { id: string; title: string }) {
    const activeUser = StoreService.getActiveUser();

    console.log({ activeUser, id, title });

    if (activeUser) {
      RealtimeDatabase.addCurrentUserIndustry({
        industryId: id,
        industryData: {
          id,
          title,
        },
      });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        draft.user.profileData.industries = {
          ...draft.user.profileData.industries,
          [id]: {
            id,
            title,
          },
        };
      },
      { saveLocal: true },
    );
  }

  removeIndustry({ id }: { id: string }) {
    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.removeCurrentUserIndustry({ industryId: id });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        delete draft.user.profileData.industries[id];
      },
      { saveLocal: true },
    );
  }

  getUserSubjects() {
    return this.getUserProfileData()?.subjects || {};
  }

  getUserHasSelectedSubjects() {
    return Object.keys(this.getUserSubjects()).length > 0;
  }

  getUserIndustries() {
    return this.getUserProfileData()?.industries || {};
  }

  getUserHasSelectedIndustries() {
    return Object.keys(this.getUserIndustries()).length > 0;
  }

  updateUserProfileAndPlaylistDataFromStore() {
    const userProfileData = StoreService.getUserProfileData();
    const userPlaylistData = StoreService.getUserPlaylistData();

    console.log("updateUserProfileAndPlaylistDataFromStore", {
      userProfileData,
      userPlaylistData,
    });

    RealtimeDatabase.updateCurrentUser({
      profileData: userProfileData || {},
      playlistData: userPlaylistData || {},
    });
  }
}

export const UserProfileService = new UserProfileServiceClass();
