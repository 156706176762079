import { useSubjectFilterOptions } from "@/store/selectors";
import { FilterOptionChip } from "../AdvizerSearchFilters";
import { ResourceConfigs } from "@/configs/resourceConfigs";
import { Group, Stack } from "@mantine/core";
import { useMemo, useState } from "react";
import { TextInput } from "../ui/TextInput";
import { IconSearch } from "@tabler/icons-react";
import { useIsLaptopOrBigger } from "@/styles/useStyles";
import { UserProfileService } from "@/services/UserProfileService";
import { useUserSubjects } from "@/store/selectors/userProfileSelectors";
import { Analytics } from "@/services/Analytics";

export const PlaylistMajorSelector = () => {
  const { subjects } = useSubjectFilterOptions();
  const [search, setSearch] = useState("");
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const userSubjects = useUserSubjects();

  const filteredSubjects = useMemo(() => {
    return subjects.filter((subject) =>
      subject.title.toLowerCase().includes(search.toLowerCase()),
    );
  }, [subjects, search]);

  const handleSelectSubject = (option: any) => {
    const alreadySelected = userSubjects?.[option.id];
    if (alreadySelected) {
      UserProfileService.removeSubject({ id: option.id });
    } else {
      UserProfileService.addSubject({
        id: option.id,
        title: option.label,
      });

      Analytics.updateUserProfile({
        action: "add_subject",
        label: option.label,
      });
    }
  };

  return (
    <Stack align="center" w="100%">
      <TextInput
        id="partnerSearchInput"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search"
        size={isLaptopOrBigger ? "sm" : "md"}
        radius="xl"
        leftSection={<IconSearch size={18} />}
        maw={350}
        w="100%"
      />

      {/* <Space h="xs" /> */}

      <Group justify="flex-start" gap="xs" maw={600}>
        {filteredSubjects.map((subject) => (
          <FilterOptionChip
            key={subject.id}
            size={isLaptopOrBigger ? "sm" : "sm"}
            checked={userSubjects?.[subject.id]}
            tab={{
              value: "subject",
              filterKey: "subjects",
              onClick: (option) => {
                handleSelectSubject(option);
                // setSelectedSubjects((prev) => {
                //   if (prev.includes(option.id)) {
                //     return prev.filter((id) => id !== option.id);
                //   }
                //   return [...prev, option.id];
                // });
              },
            }}
            option={{ id: subject.id, label: subject.title }}
            resourceConfig={ResourceConfigs.subject}
          />
        ))}
      </Group>
    </Stack>
  );
};
