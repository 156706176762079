import { useMantineTheme } from "@mantine/core";
import { useMediaQuery as useMediaQueryHook } from "usehooks-ts";

// import { useCurrentPartner } from "../hooks";

const useMediaQuery = (query: string) => {
  const matches = useMediaQueryHook(query);
  // const debouncedMatches = useDebouncedCallback(() => matches, 200);

  return matches;
};

export const useIsMobile = () => {
  const theme = useMantineTheme();
  return useMediaQuery(`(max-width: ${theme.breakpoints.mobileMax})`);
};

export const useIsTablet = () => {
  const theme = useMantineTheme();
  return useMediaQuery(
    `(min-width: ${theme.breakpoints.mobileMax}) and (max-width: ${theme.breakpoints.tabletMax})`,
  );
};

export const useIsLaptop = () => {
  const theme = useMantineTheme();
  return useMediaQuery(
    `(min-width: ${theme.breakpoints.tabletMax}) and (max-width: ${theme.breakpoints.laptopMax})`,
  );
};

export const useIsTabletOrBigger = () => {
  const theme = useMantineTheme();
  return useMediaQuery(`(min-width: ${theme.breakpoints.mobileMax})`);
};

export const useIsLaptopOrBigger = () => {
  const theme = useMantineTheme();
  return useMediaQuery(`(min-width: ${theme.breakpoints.tabletMax})`);
};

export const useIsDesktop = () => {
  const theme = useMantineTheme();
  return useMediaQuery(`(min-width: ${theme.breakpoints.laptopMax})`);
};

export const useBreakpoints = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isLaptop = useIsLaptop();
  const isTabletOrBigger = useIsTabletOrBigger();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isDesktop = useIsDesktop();

  // console.log("===============");
  // console.log({
  //   isMobile,
  //   isTablet,
  //   isTabletOrBigger,
  //   isLaptop,
  //   isLaptopOrBigger,
  //   isDesktop,
  //   breakpoints,
  // });

  return {
    isMobile,
    isTablet,
    isTabletOrBigger,
    isLaptop,
    isLaptopOrBigger,
    isDesktop,
  };
};

export const useStyles = () => {
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const theme = useMantineTheme();

  const headerHeight = isLaptopOrBigger ? 65 : 50;

  return {
    dimensions: {
      headerHeight,
      maxAppWidth: 1950,
    },
    theme,
    // colors: {
    //   ...theme.colors,
    //   background: theme.colors.slate,
    //   text: theme.colors.gray,
    // },
  };
};
