import { RealtimeDatabase } from "./RealtimeDatabase";
import { StoreService } from "./StoreService";

class PlaylistServiceClass {
  constructor() {}

  async getAllPlaylists() {
    const playlistLibraryResponse = await fetch(
      "/playlists/playlistLibrary.json",
    );
    const playlistLibraryRaw = await playlistLibraryResponse.json();
    const playlistLibrary = Object.values(playlistLibraryRaw)
      .filter((playlist: any) => !!playlist.advizers)
      .reduce((acc: any, playlist: any) => {
        acc[playlist.id] = playlist;
        return acc;
      }, {} as any);

    const playlistPromises = Object.values(playlistLibrary || {})
      .filter((playlist: any) => !!playlist.advizers)
      .map(async (playlist: any) => {
        try {
          const playlistResponse = await fetch(
            `/playlists/${playlist.id}.json`,
          );
          const playlistData = await playlistResponse.json();
          return playlistData;
        } catch (error) {
          console.error(`Error fetching playlist ${playlist.id}:`, error);
          return null;
        }
      });

    const playlists = await Promise.all(playlistPromises);

    StoreService.setAllPlaylists(
      playlists.sort(() => {
        return Math.random() - 0.5;
      }),
    );
    StoreService.setPlaylistLibrary(playlistLibrary);
    return playlistLibrary;
  }

  getUserPlaylistStepData(params: { playlistId: string; stepKey: string }) {
    return StoreService.getUserState().playlistData?.playlists?.[
      params.playlistId
    ]?.steps?.[params.stepKey];
  }

  updateUserPlaylistStepData(params: {
    playlistId: string;
    stepKey: string;
    data: any;
  }) {
    // console.log("updateUserPlaylistStepData", { params });

    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.updateCurrentUserPlaylistStepData({
        playlistId: params.playlistId,
        stepKey: params.stepKey,
        data: params.data,
      });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        if (!draft.user.playlistData.playlists[params.playlistId]) {
          draft.user.playlistData.playlists[params.playlistId] = {};
        }

        if (!draft.user.playlistData.playlists[params.playlistId].steps) {
          draft.user.playlistData.playlists[params.playlistId].steps = {};
        }

        // dont overwrite the data if it already exists, loop over the data and merge it
        const existingData =
          draft.user.playlistData.playlists[params.playlistId].steps[
            params.stepKey
          ];
        const mergedData = { ...existingData, ...params.data };

        draft.user.playlistData.playlists[params.playlistId].steps[
          params.stepKey
        ] = mergedData;
      },
      { saveLocal: true },
    );
  }

  updateUserPlaylistVideoStepData(params: {
    playlistId: string;
    stepKey: string;
    data: any;
  }) {
    console.log("updateUserPlaylistVideoStepData", { params });

    const existingTotalSecondsWatched =
      StoreService.getUserState().playlistData.playlists[params.playlistId]
        ?.steps[params.stepKey]?.totalSecondsWatched || 0;

    const existingData =
      StoreService.getUserState().playlistData.playlists[params.playlistId]
        ?.steps[params.stepKey];

    const newTotalSecondsWatched =
      existingTotalSecondsWatched + (params.data.secondsToAdd || 0);

    const newData = { ...existingData };
    if (params.data.secondsToAdd) {
      newData.totalSecondsWatched = newTotalSecondsWatched;
    }

    if (params.data.percentProgress) {
      newData.percentProgress = params.data.percentProgress;
    }

    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.updateCurrentUserPlaylistStepData({
        playlistId: params.playlistId,
        stepKey: params.stepKey,
        data: newData,
      });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        if (!draft.user.playlistData.playlists[params.playlistId]) {
          draft.user.playlistData.playlists[params.playlistId] = {};
        }

        if (!draft.user.playlistData.playlists[params.playlistId].steps) {
          draft.user.playlistData.playlists[params.playlistId].steps = {};
        }

        if (
          !draft.user.playlistData.playlists[params.playlistId].steps[
            params.stepKey
          ]
        ) {
          draft.user.playlistData.playlists[params.playlistId].steps[
            params.stepKey
          ] = {};
        }

        draft.user.playlistData.playlists[params.playlistId].steps[
          params.stepKey
        ] = newData;
      },
      { saveLocal: true },
    );
  }

  updateUserRecommendedPlaylists(playlistIds: string[]) {
    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.updateCurrentUserRecommendedPlaylists({
        playlistIds,
      });
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        draft.user.playlistData.recommendedPlaylists = playlistIds.reduce(
          (acc, id) => {
            acc[id] = true;
            return acc;
          },
          {} as any,
        );
      },
      { saveLocal: true },
    );
  }

  clearUserRecommendedPlaylists() {
    const activeUser = StoreService.getActiveUser();

    if (activeUser) {
      RealtimeDatabase.clearUserRecommendedPlaylists();
      return;
    }

    StoreService.createStateUpdater(
      (draft) => {
        draft.user.playlistData.recommendedPlaylists = {};
      },
      { saveLocal: true },
    );
  }
}

export const PlaylistService = new PlaylistServiceClass();
